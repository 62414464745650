<template>
  <v-form @submit.prevent v-model="valid" class="px-1 pb-2">
    <v-alert type="info" text border="left">
      Defina um preço diferenciado para cada tipo de membro. <br />
      <small class="text-14">
        Cada filiado só poderá comprar
        <b> um ingresso com desconto por evento. </b>
      </small>
    </v-alert>
    <div class="d-flex flex-column gap-3 px-1">
      <div
        v-for="membership in activeMemberships"
        :key="membership.id"
        class="d-flex gap-2"
      >
        <v-chip
          :color="membership.backgroundColor"
          text-color="white"
          label
          small
          class="pa-5 text-13 font-weight-bold"
          :style="{
            background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${membership.backgroundImg})`,
            backgroundSize: 'cover',
            border: `1px solid ${membership.backgroundColor || '#000'}`,
            boxShadow: `0 0 10px  ${
              membership.backgroundColor || 'rgba(0,0,0,.2)'
            }`,
          }"
        >
          {{ membership.name }}
        </v-chip>
        <v-text-field
          :value="membershipPrice[membership.id]"
          @input="setMembershipPrice(membership.id, $event)"
          outlined
          dense
          hide-details="auto"
          :placeholder="ticketBlock.price + ` (valor padrão)`"
          :prefix="`R$ `"
          type="number"
          step="0.01"
          min="3"
          :max="ticketBlock.price"
          :rules="[
            (v) => !v || parseFloat(v) >= 3 || 'O preço mínimo é R$ 3,00',
            (v) =>
              !v ||
              parseFloat(v) < parseFloat(ticketBlock.price) ||
              'O preço não pode ser maior que o valor do lote',
          ]"
        />
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["input"],
  data: () => ({
    valid: true,
  }),
  methods: {
    setMembershipPrice(membershipId, price) {
      this.membershipPrice = {
        ...this.membershipPrice,
        [membershipId]: price,
      };
    },
  },
  watch: {
    valid(val) {
      this.$emit("valid", val);
    },
  },
  computed: {
    ...mapGetters("organization", ["memberships"]),
    activeMemberships() {
      return this.memberships.filter((m) => m.active);
    },
    membershipPrice: {
      get: function () {
        return Object.fromEntries(
          this.value.map((v) => [v.membershipId, v.price])
        );
      },
      set: function (value) {
        this.$emit(
          "input",
          Object.entries(value)
            .map(([k, v]) => ({ membershipId: k, price: v }))
            .filter((v) => v.price)
        );
      },
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    ticketBlock: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    this.$emit("valid", this.valid);
  },
};
</script>

<style>
</style>