<template>
  <v-card outlined :loading="loading" class="rounded-lg">
    <v-card-title>
      <h6>Origem dos acessos</h6>
    </v-card-title>
    <v-card-text>
      <vue-perfect-scrollbar
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        style="height: 100%; max-height: 360px"
      >
        <v-simple-table dense striped>
          <thead>
            <tr>
              <th class="text-left">Origem</th>
              <th class="text-left">Total de Usuários</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="{ dimensions, metrics } in data.sessionSource.rows"
              :key="dimensions[0]"
            >
              <td>
                <i v-if="dimensions[0] === '(direct)'"> Acesso direto </i>
                <i v-else-if="dimensions[0] === '(not set)'"> Desconhecido </i>
                <span v-else>{{ dimensions[0] }}</span>
              </td>
              <td>{{ metrics[0] }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </vue-perfect-scrollbar>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  data: () => ({
    maxCities: 10,
    colorsArray: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
    ],
  }),
  computed: {
    chartSeries() {
      return this.data.viewsPerCity.rows.reduce((acc, city, index) => {
        const value = parseFloat(city.metrics[0]);
        if (index < this.maxCities + 1) acc.push(value);
        else acc[this.maxCities] += value;
        return acc;
      }, []);
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        colors: new Array(this.maxCities + 1)
          .fill(null)
          .map((_, index) =>
            index < this.maxCities
              ? this.colorsArray[index % this.colorsArray.length]
              : "#999"
          ),
        dataLabels: { enabled: false },
        labels: [
          ...this.data.viewsPerCity.rows
            .slice(0, this.maxCities)
            .map((city) =>
              city.dimensions[0] !== "(not set)"
                ? city.dimensions[0]
                : "Desconhecido"
            ),
          this.data.viewsPerCity.rows.length > this.maxCities
            ? "Outras"
            : undefined,
        ].filter((label) => label),
        xaxis: {},
        yaxis: {
          labels: {},
        },
      };
    },
  },
  props: {
    data: {
      type: Object || null,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    metricsKeys: {
      type: Object,
    },
    dimensionsKeys: {
      type: Object,
    },
  },
};
</script>

<style></style>
