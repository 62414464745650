<template>
  <div>
    <v-row>
      <v-col cols="12" sm="4">
        <v-select
          v-model="policies.daysAfterPurchase"
          :items="daysAfterPurchaseOptions"
          label="Dias após a compra"
          outlined
          dense
          item-text="label"
          item-value="value"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="policies.hoursBeforeEvent"
          :items="hoursBeforeEventOptions"
          label="Horas antes do evento"
          outlined
          dense
          item-text="label"
          item-value="value"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4">
        <v-select
          v-model="policies.transferBefore"
          :items="transferBeforeOptions"
          label="Transferencia antes do evento"
          outlined
          dense
          item-text="label"
          item-value="value"
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-alert text border="left" type="info">
      <refund-policy-text :party="{ refundPolicy }" />
    </v-alert>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import RefundPolicyText from "../../../global/party/RefundPolicyText.vue";

export default {
  components: { RefundPolicyText },
  data() {
    return {
      daysAfterPurchaseOptions: [
        { label: "7 dias", value: "7" },
        { label: "15 dias", value: "15" },
        { label: "30 dias", value: "30" },
        { label: "60 dias", value: "60" },
        { label: "90 dias", value: "90" },
        { label: "Sem restrição", value: "0" },
      ],
      transferBeforeOptions: [
        { label: "2 horas", value: "2" },
        { label: "4 horas", value: "4" },
        { label: "6 horas", value: "6" },
        { label: "12 horas", value: "12" },
        { label: "24 horas", value: "24" },
        { label: "48 horas", value: "48" },
        { label: "72 horas", value: "72" },
      ],
      hoursBeforeEventOptions: [
        { label: "12 horas", value: "12" },
        { label: "24 horas", value: "24" },
        { label: "48 horas", value: "48" },
        { label: "72 horas", value: "72" },
      ],

      policies: this.decomposeRefundPolicy(
        this.party?.refundPolicy || "7-48-48"
      ),
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        refundPolicy: this.refundPolicy,
      });
    },
    decomposeRefundPolicy(refoundPolicy) {
      try {
        const split = refoundPolicy.split("-");
        return {
          daysAfterPurchase: split[0],
          hoursBeforeEvent: split[1],
          transferBefore: split[2] || split[1],
        };
      } catch (e) {
        console.error(e);
        return {
          daysAfterPurchase: 7,
          hoursBeforeEvent: 48,
          transferBefore: 48,
        };
      }
    },
  },
  computed: {
    refundPolicy() {
      return `${this.policies.daysAfterPurchase}-${this.policies.hoursBeforeEvent}-${this.policies.transferBefore}`;
    },
  },
  watch: {
    "policies.hoursBeforeEvent": function (val, oldVal) {
      if (
        val >= this.policies.transferBefore ||
        oldVal == this.policies.transferBefore
      ) {
        this.policies.transferBefore = val;
      }
    },
    party: {
      handler: function (val, oldVal) {
        this.policies = this.decomposeRefundPolicy(
          this.party?.refundPolicy || "7-48-48"
        );
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
