<template>
  <div class="pa-3">
    <v-switch
      :input-value="options.nextTicketBlock"
      @change="setValue('nextTicketBlock', $event)"
      label="Avisar quando virar um lote"
      class="mt-0"
    />
    <v-switch
      :input-value="options.ticketBlockRemain"
      @change="setValue('ticketBlockRemain', $event)"
      label="Avisar quando restar poucos ingressos de um lote"
      class="mt-0"
    />
    <v-switch
      :input-value="options.refoundRequest"
      @change="setValue('refoundRequest', $event)"
      label="Avisar quando houver solicitação de reembolsos"
      class="mt-0"
    />
  </div>
</template>

<script>
const defaultOptions = () => ({
  nextTicketBlock: true,
  ticketBlockRemain: true,
  refoundRequest: true,
});

export default {
  props: {
    value: {
      type: {},
      default: defaultOptions(),
    },
  },
  methods: {
    setValue(key, value) {
      this.options = Object.assign({}, this.options, { [key]: !!value });
    },
  },
  computed: {
    options: {
      get() {
        return Object.assign(defaultOptions(), this.value || {});
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.$emit("input", this.options);
  },
};
</script>

<style lang="scss" scoped></style>
