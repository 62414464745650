<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6>Vendas por Promoter</h6>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <vue-perfect-scrollbar
        v-else
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
        style="max-height: 500px"
      >
        <v-data-table
          :headers="headers"
          :items="sellers"
          :items-per-page="sellers.length"
          hide-default-footer
          sort-by="total"
          sort-desc
          dense
        >
          <template v-slot:item.total="{ item }">
            {{ item.total | currency }}
          </template>
        </v-data-table>
      </vue-perfect-scrollbar>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    error: null,
    headers: [
      { text: "Promoter", value: "name" },
      { text: "Vendas", value: "quantity" },
      { text: "Valor", value: "total" },
    ],
  }),
  computed: {
    sellers() {
      const paymentsSummed = [];
      return Object.values(
        this.data.tickets
          .filter((t) => t.Payment?.status == "succeeded")
          .reduce((acc, t) => {
            if (!t.Seller) return acc;
            if (paymentsSummed.includes(t.Payment.id)) return acc;
            paymentsSummed.push(t.Payment.id);

            const seller = t.Seller.id;
            acc[seller] = acc[seller] || {
              name: t.Seller.name,
              total: 0,
              quantity: 0,
            };

            acc[seller].total += t.Payment.amount;
            acc[seller].quantity += 1;

            return acc;
          }, {})
      );
    },
    chartSeries() {
      const response = [];

      var approved = Object.values(this.sellers).map((tickets) => {
        return tickets.reduce((acc, t) => {
          if (t.Payment.status !== "succeeded") return acc;
          return acc + (this.display === 0 ? t.Payment.amount : 1);
        }, 0);
      });

      return approved;
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: Object.keys(this.sellers),
        colors: [
          "#5e5ce6",
          "#82b1ff",
          "#ff5252",
          "#4caf50",
          "#ff5722",
          "#242939",
        ],
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display === 0
                ? value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : `${value} ingressos`,
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
