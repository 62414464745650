<template>
  <v-dialog v-model="dialog" max-width="500" @click:outside="close" scrollable>
    <v-card>
      <v-card-title>Escolha um motivo</v-card-title>
      <v-card-text>
        <v-card
          v-for="m in messages"
          :key="m.id"
          outlined
          @click="sendMessage(m)"
          class="pa-6 mb-4"
        >
          <p class="text-subtitle-1 mb-0">
            {{ m.reason }}
          </p>
          <small v-if="$vuetify.breakpoint.smAndUp">
            {{ m.message }}
          </small>
        </v-card>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn @click="dialog = false" text> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    recallInfo: false,
    messagesTemplates: [
      {
        id: "1",
        reason: "Estou a caminho para recolher o dinheiro",
        message:
          "Olá, {name}! Estou a caminho para recolher o valor de {amount} referente as vendas em seu nome. Por favor deixe o valor separado!",
      },
      {
        id: "2",
        reason: "Preciso recolher o dinheiro urgente",
        message:
          "Olá, {name}! Preciso recolher assim que possível o valor de {amount} referente as vendas em seu nome. Obrigado!",
      },
      {
        name: "3",
        reason: "Quero marcar para recolher o dinheiro",
        message:
          "Olá, {name}! Quando podemos marcar para recolher o valor de {amount} referente as vendas em seu nome? Obrigado!",
      },
    ],
  }),
  methods: {
    sendMessage(type) {
      let userPhone = this.recallInfo.phone.replaceAll(/\D/g, "");
      window.open(
        `https://api.whatsapp.com/send?phone=55${userPhone}&text=${type.message}`,
        "_blank"
      );
      this.dialog = false;
    },
    open(recallInfo) {
      this.dialog = true;
      this.recallInfo = recallInfo;
    },
    close() {
      this.dialog = false;
      recallInfo = false;
    },
    formatMessage(message) {
      return message
        .replace("{name}", this.recallInfo.name.split(" ")[0])
        .replace(
          "{amount}",
          this.currency(this.recallInfo.sold - this.recallInfo.recall)
        );
    },
    currency(value) {
      value = parseFloat(value);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },

  computed: {
    messages() {
      if (!this.recallInfo) return [];
      return this.messagesTemplates.map((message) => ({
        ...message,
        message: this.formatMessage(message.message),
      }));
    },
  },
};
</script>

<style></style>
