var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500px","overlay-opacity":"0.9","persistent":_vm.loading},on:{"click:outside":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!!_vm.ticket && !_vm.succeeded)?_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Reembolsar Ingresso ")]),_c('v-card-text',{staticClass:"pb-2"},[_c('v-form',{ref:"transferForm",staticClass:"mb-4",on:{"submit":_vm.refund},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h6',{staticClass:"text-center"},[_vm._v("Reembolso")]),_c('v-btn-toggle',{staticClass:"d-flex mb-4",attrs:{"mandatory":"","width":"100%"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-btn',{staticStyle:{"width":"50%"},attrs:{"text":""}},[_vm._v(" Total ")]),_c('v-btn',{staticStyle:{"width":"50%"},attrs:{"text":""}},[_vm._v(" Parcial ")])],1),_c('v-scroll-y-transition',{attrs:{"leave-absolute":""}},[(_vm.type === 1)?_c('v-text-field',{attrs:{"label":"Valor","type":"number","step":"0.01","min":"0","max":_vm.ticket.Payment.amount,"required":"","outlined":"","rules":[
              (v) => !!v || 'Valor é obrigatório',
              (v) =>
                v <= _vm.ticket.Payment.amount ||
                'Valor não pode ser maior que o valor do ingresso',
              (v) => v > 0 || 'Valor deve ser maior que 0',
            ]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}):_vm._e()],1)],1),_c('v-alert',{staticClass:"mb-0",attrs:{"type":"info","border":"left"}},[_vm._v(" Atenção: apos reembolsar um ingresso não será possível utilizar, transferir ou reembolsar novamente. ")]),(_vm.error)?_c('v-alert',{staticClass:"mt-2 mb-0",attrs:{"type":"error","border":"left"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()],1),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","block":"","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.refund}},[_vm._v(" Reembolsar ")]),_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","text":"","disabled":_vm.loading,"block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fechar ")])],1)],1):_vm._e(),(_vm.succeeded)?_c('v-card',[_c('v-card-text',[_c('div',{staticClass:"text-center pt-8"},[_c('v-avatar',{staticClass:"mb-4",attrs:{"size":"60","color":"success lighten-4"}},[_c('v-icon',{attrs:{"x-large":"","color":"success darken-2"}},[_vm._v("mdi-check")])],1),_c('h6',[_vm._v(_vm._s(_vm.result.message))])],1)]),_c('v-card-actions',{staticClass:"d-flex flex-column"},[_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","text":"","block":""},on:{"click":function($event){return _vm.close(true)}}},[_vm._v(" Fechar ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }