<template>
  <v-card outlined :loading="loading" class="rounded-lg">
    <v-card-title>
      <h6>Acessos por sistema operacional</h6>
    </v-card-title>
    <v-card-text>
      <apexCharts
        type="donut"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  data: () => ({
    maxOperacionalSystem: 10,
    colorsArray: [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#546E7A",
    ],
  }),
  computed: {
    chartSeries() {
      return this.data.viewsPerOperatingSystem.rows.reduce(
        (acc, operatingSystem, index) => {
          const value = parseFloat(operatingSystem.metrics[0]);
          if (index < this.maxOperacionalSystem + 1)
            acc.push(value);
          else acc[this.maxOperacionalSystem] += value;
          return acc;
        },
        []
      );
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        colors: new Array(this.maxOperacionalSystem + 1)
          .fill(null)
          .map((_, index) =>
            index < this.maxOperacionalSystem
              ? this.colorsArray[index % this.colorsArray.length]
              : "#999"
          ),
        dataLabels: { enabled: false },
        labels: [
          ...this.data.viewsPerOperatingSystem.rows
            .slice(0, this.maxOperacionalSystem)
            .map((operatingSystem) =>
              operatingSystem.dimensions[0] !== "(not set)"
                ? operatingSystem.dimensions[0]
                : "Desconhecido"
            ),
          this.data.viewsPerOperatingSystem.rows.length > this.maxOperacionalSystem
            ? "Outros"
            : undefined,
        ].filter((label) => label),
        xaxis: {},
        yaxis: { labels: {} },
      };
    },
    // reducedByOperacionalSystem() {
    //   if (!this.data?.operatingSystem) return [];
    //   const totalUserKey = this.metricsKeys.totalUsers;
    //   return Object.entries(this.data.operatingSystem)
    //     .map(([operatingSystem, metrics]) => ({
    //       operatingSystem,
    //       metrics: Object.entries(metrics).map(([metric, value]) => ({
    //         metric,
    //         ...value.reduce(
    //           (acc, curr) => {
    //             acc.count++;
    //             acc.sum += parseFloat(curr);
    //             return acc;
    //           },
    //           { count: 0, sum: 0 }
    //         ),
    //       })),
    //     }))
    //     .sort(
    //       (a, b) => b.metrics[totalUserKey].sum - a.metrics[totalUserKey].sum
    //     );
    // },
  },
  props: {
    data: {
      type: Object || null,
      required: true,
    },
    loading: {
      type: Boolean,
    },
    metricsKeys: {
      type: Object,
    },
    dimensionsKeys: {
      type: Object,
    },
  },
};
</script>

<style></style>
