<template>
  <div>
    <v-menu :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          icon
          small
          elevation="0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon small>mdi-filter-menu</v-icon>
        </v-btn>
      </template>

      <v-card class="d-flex flex-column gap-3 pa-5 pt-7">
        <!-- <v-select
      dense
      hide-details
      item-value="id"
      :items="items"
      :menu="true"
      multiple
      v-model="filter.ticketBlock"
      placeholder="Filtrar"
      label="Filtrar por lote"
      class="smallInput"
    >
      <template v-slot:selection="{ index }">
        <div v-if="index === 0">
          <v-chip
            color="primary"
            x-small
            v-for="selectedItem in selectedLabels"
            :key="selectedItem"
            >{{ selectedItem }}</v-chip
          >
        </div>
      </template>
      <template v-slot:item="{ item, attrs }">
        <v-list-item
          @click.stop="toogleTicketGroup(item)"
          v-if="item.type == 'TicketGroup'"
          :class="
            ticketGroupSelectionStatus[item.tgId][0] === 'all'
              ? 'v-list-item--active primary--text'
              : ''
          "
          class="d-flex"
          style="margin: 0 -16px"
        >
          <v-simple-checkbox
            @click.stop="toogleTicketGroup(item)"
            :value="ticketGroupSelectionStatus[item.tgId][0] === 'all'"
            :indeterminate="ticketGroupSelectionStatus[item.tgId][0] === 'some'"
          />
          <span class="text-14 font-weight-bold"> {{ item.title }} </span>
        </v-list-item>
        <div
          v-bind="attrs"
          @click.stop="toggleTicketBlock(item.id)"
          v-if="item.type == 'TicketBlock'"
          class="pl-5 d-flex align-center"
        >
          <v-simple-checkbox
            @click.stop="toggleTicketBlock(item.id)"
            :value="filter.ticketBlock.includes(item.id)"
          />
          <span> {{ item.title }} </span>
        </div>
      </template>
    </v-select> -->

        <v-autocomplete
          dense
          outlined
          hide-details
          return-object
          item-value="id"
          :items="partySellers"
          multiple
          :filter="filterSearch"
          v-model="filter.seller"
          placeholder="Filtrar"
          class="mediumInput"
          label="Filtrar por vendedor"
        >
          <template v-slot:selection="{ index }">
            <div class="d-flex align-center" v-if="index === 0">
              <!-- <v-chip color="primary" x-small>{{ sellersOptions[0] }}</v-chip> -->
              <div class="d-flex">
                <v-chip x-small v-if="sellersOptions[1].online">Online</v-chip>
                <v-chip x-small v-if="sellersOptions[1].user">
                  {{ sellersOptions[1].user }} Vendedor{{
                    sellersOptions[1].user === 1 ? "" : "es"
                  }}
                </v-chip>
                <v-chip x-small v-if="sellersOptions[1].pos"
                  >{{ sellersOptions[1].pos }} Pdv</v-chip
                >
              </div>
            </div>
          </template>
          <template v-slot:item="{ item }">
            <div v-if="item.type === 'online'">
              <span>Online</span>
            </div>
            <div v-else-if="item.type === 'pos'">
              <v-icon>mdi-printer-pos</v-icon>
              {{ item.Address?.name }}
            </div>
            <div class="d-flex align-center gap-2" v-else>
              <base-avatar :src="item.photo" :seed="item.id" :size="24" />
              {{ item.name }}
            </div>
          </template>
        </v-autocomplete>

        <v-switch
          hide-details
          class="mt-0"
          v-model="filter.courtesy"
          label="Incluir Cortesias"
        ></v-switch>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import SELLER from "@/services/admin/party/seller";
import TICKET from "@/services/admin/ticket";
import party from "../../../../data/spotlight/party";

export default {
  data: () => ({
    loading: false,
    error: false,
    sellers: [],
    posSellers: [],
    ticketGroups: [],
    filter: {
      ticketBlock: [],
      seller: [],
      courtesy: false,
    },
  }),
  watch: {
    filter: {
      handler() {
        this.$emit("input", this.filter);
      },
      deep: true,
    },
  },
  methods: {
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
        this.filter.ticketBlock = response.ticketGroups.reduce(
          (acc, tg) => [...acc, ...tg.TicketBlock.map((tb) => tb.id)],
          []
        );
      } catch (e) {
        console.log(e);
      }
    },
    toggleTicketBlock(ticketBlockId) {
      if (this.filter.ticketBlock.includes(ticketBlockId)) {
        this.filter.ticketBlock = this.filter.ticketBlock.filter(
          (tb) => tb !== ticketBlockId
        );
      } else {
        this.filter.ticketBlock.push(ticketBlockId);
      }
    },
    toogleTicketGroup(ticketGroup) {
      if (this.ticketGroupSelectionStatus[ticketGroup.tgId][0] === "all") {
        this.filter.ticketBlock = this.filter.ticketBlock.filter(
          (tb) => !ticketGroup.ticketBlocks.includes(tb)
        );
      } else {
        var newSelection = new Set([
          ...this.filter.ticketBlock,
          ...ticketGroup.ticketBlocks,
        ]);
        this.filter.ticketBlock = Array.from(newSelection);
      }
    },
    filterSearch(item, queryText) {
      const itemName = item.name || item.Address?.name || item.type;
      return itemName.toLowerCase().includes(queryText.toLowerCase());
    },
    async getSellers() {
      try {
        this.loading = true;
        const { sellers, posSellers } = await SELLER.getAll(
          this.party.organizationId,
          this.party.id
        );

        this.sellers = sellers.map((s) => ({
          id: s.id,
          name: s.name,
          photo: s.photo,
          type: "user",
        }));

        this.posSellers = posSellers.map((pos) => ({
          id: pos.id,
          Address: { name: pos.Address.name },
          type: "pos",
        }));
        this.filter.seller = this.partySellers;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getSellers();
    this.getTicketGroups();
  },

  computed: {
    sellersOptions() {
      const sellerType = this.filter.seller.reduce(
        (acc, seller) => {
          acc[seller.type]++;
          return acc;
        },
        { pos: 0, user: 0, online: 0 }
      );
      if (this.filter.seller.length === this.partySellers.length)
        return ["Todos", sellerType];
      if (
        this.filter.seller.length === 1 &&
        this.filter.seller[0].type === "online"
      )
        return ["Online", sellerType];
      return [this.filter.seller.length, sellerType];
    },
    partySellers() {
      return [
        { id: "online", type: "online" },
        ...this.sellers,
        ...this.posSellers,
      ];
    },
    selectedLabels() {
      const ticketGroups = Object.entries(this.ticketGroupSelectionStatus);
      if (ticketGroups.length === 0) return [];
      if (ticketGroups.every(([_, status]) => status[0] === "all"))
        return ["Todos"];
      return ticketGroups.reduce((acc, [tgId, status]) => {
        const ticketGroup = this.ticketGroups.find((tg) => tg.id === tgId);
        if (status[0] === "none") return acc;
        acc.push(
          `${ticketGroup.name}: ${status[0] === "all" ? "Todos" : status[1]}`
        );
        return acc;
      }, []);
    },
    ticketGroupSelectionStatus() {
      return this.items.reduce((acc, tg) => {
        if (tg.type == "TicketBlock") return acc;
        const selected = this.filter.ticketBlock.filter((tbId) =>
          tg.ticketBlocks.includes(tbId)
        );
        acc[tg.tgId] =
          selected.length === tg.ticketBlocks.length
            ? ["all", selected.length]
            : selected.length === 0
            ? ["none", selected.length]
            : ["some", selected.length];
        return acc;
      }, {});
    },
    items() {
      return this.ticketGroups.reduce((acc, tg) => {
        acc.push({
          title: tg.name,
          type: "TicketGroup",
          tgId: tg.id,
          ticketBlocks: tg.TicketBlock.map(({ id }) => id),
        });

        tg.TicketBlock.forEach((t) => {
          acc.push({
            title: t.name,
            type: "TicketBlock",
            id: t.id,
          });
        });
        return acc;
      }, []);
    },
  },
  props: {
    value: Object,
    party: Object,
  },
};
</script>

<style lang="scss" scooped>
.smallInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 10px;
          }
        }
      }
    }
  }
}

.mediumInput {
  .v-input__control {
    .v-input__slot {
      .v-select__slot {
        .v-select__selections {
          input {
            width: 70px;
          }
        }
      }
    }
  }
}
</style>
