<template>
  <v-card outlined :loading="loading || internalLoading">
    <v-card-title class="justify-space-between">
      <h6>Vendas para Membros</h6>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <template v-else>
        <v-data-table
          :headers="headers"
          :items="ticketsByMembership"
          single-expand
          show-expand
        >
          <template v-slot:item.membership.name="{ item }">
            <v-chip
              :color="item.membership?.backgroundColor"
              text-color="white"
              label
              small
              class="font-weight-medium"
              :style="{
                background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${
                  item.membership?.backgroundImg
                }), ${item.membership?.backgroundColor || '#3333'}80`,
                backgroundSize: 'cover',
                border: `1px solid ${
                  item.membership?.backgroundColor || '#000'
                }`,
                boxShadow: `0 0 10px  ${
                  item.membership?.backgroundColor || 'rgba(0,0,0,.2)'
                }`,
              }"
            >
              {{ item.membership?.name }}
            </v-chip>
          </template>
          <template v-slot:item.tickets.length="{ item }">
            <small> {{ item.total | currency(true) }} </small>
            <p class="mb-0 font-weight-bold">{{ item.tickets.length }}</p>
          </template>
          <template v-slot:item.discount="{ item }">
            <small>
              -{{
                (item.discount / (item.total + item.discount)) | percentage(0)
              }}
            </small>
            <p class="mb-0 font-weight-bold">
              {{ item.discount | currency(true) }}
            </p>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="py-2">
                <v-data-table
                  dense
                  :headers="innerHeader"
                  :items="item.ticketBlocks"
                  :items-per-page="item.ticketBlocks.length"
                  hide-default-footer
                >
                  <template v-slot:item.TicketBlock.name="{ item }">
                    <small>{{ item.TicketBlock.TicketGroup.name }}</small>
                    <p class="mb-0 font-weight-bold">
                      {{ item.TicketBlock.name }}
                    </p>
                  </template>

                  <template v-slot:item.tickets.length="{ item }">
                    <small> {{ item.total | currency(true) }} </small>
                    <p class="mb-0 font-weight-bold">
                      {{ item.tickets.length }}
                    </p>
                  </template>
                  <template v-slot:item.discount="{ item }">
                    <small>
                      -{{
                        (item.discount / (item.total + item.discount))
                          | percentage(0)
                      }}
                    </small>
                    <p class="mb-0 font-weight-bold">
                      {{ item.discount | currency(true) }}
                    </p>
                  </template>
                </v-data-table>
              </div>
            </td>
          </template>
        </v-data-table>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import MEMBERSHIP from "@/services/admin/membership";
import PAYMENT from "@/utils/payment";

export default {
  data: () => ({
    error: null,
    internalLoading: false,
    memberships: {},
    ticketStatus: PAYMENT.ticketStatus,
    headers: [
      { text: "Filiação", value: "membership.name" },
      { text: "Vendas", value: "tickets.length", align: "center" },
      { text: "Desconto", value: "discount", align: "center" },
      { text: "", value: "data-table-expand" },
    ],
    innerHeader: [
      { text: "Lote", value: "TicketBlock.name" },
      { text: "Vendas", value: "tickets.length", align: "center" },
      { text: "Desconto", value: "discount", align: "center" },
    ],
  }),
  methods: {
    async getMemberships() {
      try {
        this.error = null;
        this.internalLoading = true;
        const { memberships } = await MEMBERSHIP.getAll(
          this.selectedOrganization.id
        );
        this.memberships = Object.fromEntries(
          memberships.map((m) => [m.id, m])
        );
      } catch (error) {
        this.error = error.message;
      } finally {
        this.internalLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ticketsForMemberships() {
      return this.data.tickets.filter(
        (t) =>
          !!t.Payment.MembershipFiliation &&
          !["canceled", "pending"].includes(t.Payment.status)
      );
    },
    ticketsByMembership() {
      const groupedTickets = this.ticketsForMemberships.reduce((acc, curr) => {
        const { membershipId } = curr.Payment.MembershipFiliation;
        acc[membershipId] = acc[membershipId] || [];
        acc[membershipId].push(curr);
        return acc;
      }, {});

      return Object.entries(groupedTickets).map(([membershipId, tickets]) => {
        const { discount, total, ticketBlocks } = tickets.reduce(
          (acc, { TicketBlock, Payment }) => {
            acc.total += Payment.amount;
            acc.discount += Payment.discount;

            if (!acc.ticketBlocks[TicketBlock.id])
              acc.ticketBlocks[TicketBlock.id] = {
                TicketBlock,
                tickets: [],
                total: 0,
                discount: 0,
              };

            acc.ticketBlocks[TicketBlock.id].tickets.push({
              ...Payment,
              Owner: Payment.Owner,
            });
            acc.ticketBlocks[TicketBlock.id].total += Payment.amount;
            acc.ticketBlocks[TicketBlock.id].discount += Payment.discount;

            return acc;
          },
          { total: 0, discount: 0, ticketBlocks: {} }
        );

        return {
          total,
          discount,
          tickets,
          ticketBlocks: Object.values(ticketBlocks),
          membership: this.memberships[membershipId],
        };
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.getMemberships();
  },
};
</script>

<style></style>
