<template>
  <div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-card-text v-else class="px-0">
      <v-data-table
        :headers="headers"
        :items="saleLinks"
        :loading="loading"
        :expanded.sync="expanded"
        single-expand
        show-expand
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-spacer />
            <v-btn
              icon
              @click="getSaleLinks"
              :loading="loading"
              :disabled="blockRefresh"
              class="ml-2"
              :small="$vuetify.breakpoint.mdAndDown"
            >
              <v-icon :small="$vuetify.breakpoint.mdAndDown">
                mdi-refresh
              </v-icon>
            </v-btn>
            <v-btn
              @click="newSaleLink"
              :disabled="loading"
              class="ml-2"
              :small="$vuetify.breakpoint.mdAndDown"
              color="primary"
            >
              <v-icon :small="$vuetify.breakpoint.mdAndDown" left>
                mdi-plus
              </v-icon>
              Adicionar link
            </v-btn>
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="qrCode(item)" :disabled="loading" class="ml-2">
            <v-icon small>mdi-qrcode-scan</v-icon>
          </v-btn>
          <v-btn
            icon
            @click="editSaleLink(item)"
            :disabled="loading"
            class="ml-2"
          >
            <v-icon small>mdi-qrcode-edit</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="pb-4 px-0" :colspan="headers.length">
            {{ item }}
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <sale-link-modal @success="getSaleLinks" :party="party" />
    <sale-link-qr />
  </div>
</template>
<script>
import ORGANIZATION from "@/services/admin/organization";
import moment from "moment";
import OfflineRecallSendMessage from "../modals/OfflineRecallSendMessage.vue";
import OfflinePaymentsRow from "./OfflinePaymentsRow.vue";
import OfflineRecallModal from "../modals/OfflineRecallModal.vue";
import SaleLinkModal from "./modal/SaleLinkModal.vue";
import SaleLinkQr from "./modal/SaleLinkQr.vue";

export default {
  components: {
    OfflineRecallSendMessage,
    OfflinePaymentsRow,
    OfflineRecallModal,
    SaleLinkModal,
    SaleLinkQr,
  },
  name: "OfflinePaymentsManagement",
  data: () => ({
    loading: true,
    blockRefresh: false,
    error: false,
    saleLinks: [],
    lastUpdate: null,
    expanded: [],
    headers: [
      {
        text: "Nome",
        value: "name",
        sortable: true,
        align: "start",
      },
      {
        text: "Acessos",
        value: "access",
        sortable: true,
      },
      {
        text: "Vendas",
        value: "sold",
        sortable: true,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
      },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    async getSaleLinks() {
      try {
        this.error = null;
        this.loading = true;
        this.blockRefresh = true;

        const { saleLinks } = await ORGANIZATION.party.saleLink.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.saleLinks = saleLinks;

        this.lastUpdate = moment().format("HH:mm:ss");
        this.enableRefresh(10000);
      } catch (e) {
        this.error = e.message || "Erro ao carregar os links de venda";
        this.enableRefresh(5000);
      } finally {
        this.loading = false;
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
    newSaleLink() {
      this.$emit("sale-link-modal");
    },
    editSaleLink(item) {
      this.$emit("sale-link-modal", item);
    },
    qrCode(item) {
      this.$emit("sale-link-qrcode", item);
    },
  },
  mounted() {
    this.getSaleLinks();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
