<template>
  <v-dialog v-model="dialog" width="350" scrollable>
    <v-card>
      <v-card-title class="text-h6"> Link de venda </v-card-title>
      <v-card-text class="pt-1 pb-0">
        <div class="d-flex justify-center">
          <qrcode-vue
            class="pa-4 white"
            :value="url"
            size="250"
            level="M"
            :margin="5"
            ref="qrCode"
            render-as="svg"
          />
        </div>
        <v-dialog-transition v-if="!!alert.type">
          <v-alert :type="alert.type" border="left" class="ma-2 mb-0">
            {{ alert.message }}
          </v-alert>
        </v-dialog-transition>
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn class="my-2" text block @click="download">
              Baixar QR Code
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn class="my-2" text block @click="copyUrl">
              Copiar Link
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import QrcodeVue from "qrcode.vue";

export default {
  components: { QrcodeVue },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    saleLink: false,
    ticketGroups: [],
    alert: {
      type: null,
      message: null,
    },
  }),

  methods: {
    open(data = {}) {
      this.saleLink = Object.assign({}, data);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.loading = false;
        this.error = false;
        this.saleLink = false;
      });
    },
    download() {
      const div = this.$refs.qrCode.$el;
      const svg = div.querySelector("svg");
      svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      var svgData = svg.outerHTML;
      var preface = '<?xml version="1.0" standalone="no"?>\r\n';
      var svgBlob = new Blob([preface, svgData], {
        type: "image/svg+xml;charset=utf-8",
      });
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = this.saleLink.name + ".svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    copyUrl() {
      navigator.clipboard
        .writeText(this.url)
        .then(
          () => {
            this.alert.type = "success";
            this.alert.message = "Link copiado com sucesso!";
          },
          (err) => {
            this.alert.type = "error";
            this.alert.message = "Erro ao copiar link!";
          }
        )
        .finally(() => {
          setTimeout(() => {
            this.alert.type = null;
            this.alert.message = null;
          }, 3000);
        });
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    url() {
      if (!this.saleLink) return "";
      return `${window.location.origin}/shop/${this.selectedOrganization.slug}/${this.saleLink.partyId}#${this.saleLink.id}`;
    },
  },
  mounted() {
    this.$parent.$on("sale-link-qrcode", this.open);
  },
};
</script>

<style></style>
