<template>
  <div class="pa-3 pt-0">
    <div class="d-flex align-center">
      <v-btn icon class="" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer />
      <v-btn text small @click="focusToday" :disabled="todayIsVisible">
        Hoje
      </v-btn>
      <v-spacer />
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
    <v-calendar
      ref="calendar"
      v-model="value"
      :weekdays="weekday"
      :type="type"
      :events="events"
      :event-overlap-mode="mode"
      :event-overlap-threshold="30"
      :event-height="45"
      :event-margin-bottom="2"
      :event-color="getEventColor"
      @change="changeCalendar"
      @click:date="viewDay"
      @click:event="showTransaction"
    >
      <template v-slot:event="{ event }">
        <div class="mx-2">
          <p class="mb-0 font-weight-bold">
            <v-icon
              x-small
              left
              class="rounded-circle"
              :class="event.data.amount > 0 ? 'success' : 'danger'"
            >
              <!-- {{ event.data.amount > 0 ? "mdi-tray-plus" : "mdi-tray-minus" }} -->
              <!-- {{ event.data.amount > 0 ? "mdi-tray-arrow-up" : "mdi-tray-arrow-down" }} -->
              {{ event.data.amount > 0 ? "mdi-arrow-up" : "mdi-arrow-down" }}
              <!-- {{ event.data.amount > 0 ? "mdi-plus" : "mdi-minus" }} -->
            </v-icon>
            {{ event.data.amount | currency }}<br />
          </p>
          {{ event.name }}
        </div>
      </template>
    </v-calendar>
    <v-menu
      v-model="selectedOpen"
      :close-on-content-click="false"
      :activator="selectedElement"
      offset-x
    >
      <v-card
        color="grey lighten-4"
        :min-width="$vuetify.breakpoint.smAndUp ? 600 : null"
        flat
        v-if="selectedOpen && !!selectedEvent.data"
      >
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title class="justify-space-between align-center">
            {{ selectedEvent.data.amount | currency }}
            <v-chip
              class="ml-2"
              x-small
              color="white"
              :text-color="statusSummary[selectedEvent.data.status].color"
              label
            >
              {{ statusSummary[selectedEvent.data.status].text }}
            </v-chip>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="max-height: 60vh"
          >
            <component
              :is="selectedEvent.component"
              :object="selectedEvent.data.object"
              @edit="selectedOpen = false"
            ></component>
          </vue-perfect-scrollbar>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text @click="selectedOpen = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <div class="d-flex align-center mt-1 text-12">
      <v-spacer />
      <b>Legendas:</b>
      <v-icon x-small class="rounded-circle success ml-2 mr-1">
        mdi-arrow-up
      </v-icon>
      Entrada
      <v-icon x-small class="rounded-circle danger ml-2 mr-1">
        mdi-arrow-down
      </v-icon>
      Saída
      <v-divider vertical class="mx-2" />
      <v-chip
        label
        v-for="({ text, color }, key) in statusSummary"
        :key="key"
        class="mr-2"
        :color="color"
        x-small
      >
        {{ text }}
      </v-chip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import TransactionRow from "./Rows/TransactionRow.vue";
import OfflineRecallsRow from "./Rows/OfflineRecallsRow.vue";
import PaymentsRow from "./Rows/PaymentsRow.vue";
export default {
  data: () => ({
    error: null,
    focus: null,
    start: null,
    end: null,
    type: "month",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    expandComponents: {
      transaction: "TransactionRow",
      offlineRecalls: "OfflineRecallsRow",
      payments: "PaymentsRow",
      memberships: "PaymentsRow",
    },
    statusSummary: {
      PENDING: {
        text: "Pendente",
        color: "info",
      },
      DONE: {
        text: "Efetivada",
        color: "success",
      },
      DELETED: {
        text: "Cancelada",
        color: "error",
      },
    },
  }),
  methods: {
    focusToday() {
      this.value = moment().format("YYYY-MM-DD");
    },
    changeCalendar({ start, end }) {
      this.start = start;
      this.end = end;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = this.type == "day" ? "month" : "day";
    },
    getEventColor(event) {
      return event.color;
    },
    filterTransactions({ start, end, dateFormat = "YYYY-MM-DD" }) {
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      return this.report.data.filter((t) => {
        const date = moment(t.date).toDate();
        return date >= min && date <= max;
      });
    },
    showTransaction({ nativeEvent, event }) {
      if (!event.component) return;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
  },
  computed: {
    events() {
      if (!this.start || !this.end) return [];
      const configs = {
        default: {
          timed: true,
          format: "YYYY-MM-DD HH:mm",
        },
        payments: {
          timed: false,
          format: "YYYY-MM-DD",
        },
        refundedPayments: {
          timed: false,
          format: "YYYY-MM-DD",
        },
        memberships: {
          timed: false,
          format: "YYYY-MM-DD",
        },
      };

      var transactions = this.filterTransactions({
        start: this.start,
        end: this.end,
      });

      transactions = transactions.map((t) => {
        const config = configs[t.type] || configs.default;

        return {
          name: t.description,
          start: moment(t.date).format(config.format),
          end: moment(t.date)
            .add(config.timed && this.type == "day" ? 1 : 0, "hour")
            .format(config.format),
          color: this.statusSummary[t.status].color,
          component: this.expandComponents[t.type] || undefined,
          timed: config.timed,
          data: t,
        };
      });
      return transactions;
    },
    todayIsVisible() {
      if (!this.value) return true;
      return moment(this.value).isSame(moment(), this.type);
    },
  },
  filters: {},
  components: {
    TransactionRow,
    OfflineRecallsRow,
    PaymentsRow,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
