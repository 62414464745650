<template>
  <v-dialog v-model="dialog" width="300px">
    <v-card>
      <v-card-title>
        <span class="headline">Compartilhar evento</span>
      </v-card-title>
      <v-card-text v-if="party">
        <qrcode-vue
          class="d-flex justify-center ma-1 pa-5 mb-2 white rounded"
          :value="url"
          size="200"
          level="M"
          :margin="5"
          ref="qrCode"
          render-as="svg"
        />

        <v-card
          v-if="seller"
          class="d-flex gap-2 align-center pa-2 mb-2"
          outlined
        >
          <baseAvatar
            :size="40"
            :src="seller.photo"
            :seed="seller.id"
          ></baseAvatar>
          <div class="flex-grow-1">
            <p class="text-overline lh-1 mb-0">Promoter</p>
            <p class="mb-0 font-weight-bold">{{ seller.name | firstName }}</p>
          </div>

          <v-switch
            v-model="includeSeller"
            color="primary"
            dense
            hide-details
            class="ma-0"
          />
        </v-card>

        <v-alert v-if="party.private" color="info" dense text>
          <b>Este evento é privado</b>, portanto, somente convidados podem
          acessá-la.
        </v-alert>
        <div class="d-flex flex-column gap-y-2">
          <v-btn @click="download" color="primary" text small block>
            <v-icon left>mdi-download</v-icon>
            Baixar QR Code
          </v-btn>
          <v-btn @click="copyLink" :disabled="linkCopied" color="primary" block>
            <v-icon left>
              {{ linkCopied ? "mdi-check" : "mdi-content-copy" }}
            </v-icon>
            {{ linkCopied ? "Link copiado" : "Copiar link" }}
          </v-btn>
          <v-btn @click="share" color="primary" block>
            <v-icon left>mdi-share-variant</v-icon>
            Compartilhar
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    QrcodeVue,
  },
  data: () => ({
    dialog: false,
    party: null,
    organization: null,
    seller: null,
    includeSeller: true,
    linkCopied: false,
  }),
  methods: {
    share() {
      navigator.share({
        title: this.party.name,
        text: "Compartilhar evento",
        url: this.url,
      });
    },
    copyLink() {
      navigator.clipboard.writeText(this.url);
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    open({ party, organization, seller }) {
      this.party = party;
      this.organization = organization;
      this.seller = seller;
      this.dialog = true;
    },
    download() {
      const div = this.$refs.qrCode.$el;
      const svg = div.querySelector("svg");
      svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
      var svgData = svg.outerHTML;
      var preface = '<?xml version="1.0" standalone="no"?>\r\n';
      var svgBlob = new Blob([preface, svgData], {
        type: "image/svg+xml;charset=utf-8",
      });
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = this.party.name.trim() + ".svg";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
  computed: {
    url() {
      if (!this.party || !this.organization) return null;
      const url = new URL(window.location.origin);
      url.pathname = `/shop/${this.organization.slug}/${
        this.party.slug || this.party.id
      }`;

      if (this.seller && this.includeSeller) {
        url.searchParams.set("seller", this.seller.id);
      }

      return url.href;
    },
  },
};
</script>

<style></style>
