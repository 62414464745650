<template>
  <v-dialog v-model="dialog" max-width="500" @click:outside="close" :persistent="loading">
    <v-card>
      <v-card-title>Criar novo Recebimento</v-card-title>
      <v-card-text v-if="!!item">
        <v-form v-model="valid">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="offlineRecall.amount"
                :rules="[
                  (v) => !!v || 'Valor é obrigatório',
                  (v) => v > 0 || 'Valor deve ser maior que 0',
                ]"
                prefix="R$"
                label="Valor recebido"
                type="number"
                step="0.01"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="offlineRecall.date"
                label="Data do recebimento"
                type="datetime-local"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                label="Forma de recebimento"
                v-model="offlineRecall.paymentType"
                :items="paymentTypeList"
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-alert type="warning" v-if="hasCredit" text border="left">
                Esse promoter ficará com {{ hasCredit | currency }} de crédito
              </v-alert>
              <v-alert type="info" text border="left">
                Você está recebendo {{ offlineRecall.amount || 0 | currency }} de
                {{ item.name
                }}{{
                  offlineRecall.date
                    ? "em " + $options.filters.date(offlineRecall.date)
                    : ""
                }}{{
                  offlineRecall.paymentType &&
                  offlineRecall.paymentType != "UNKNOWN"
                    ? " através de " + paymentTypes[offlineRecall.paymentType]
                    : ""
                }}
              </v-alert>
              <v-alert type="error" text v-if="error" border="left">{{
                error
              }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-4">
        <v-spacer></v-spacer>
        <v-btn @click="close" text :disabled="loading"> Cancelar </v-btn>
        <v-btn
          :loading="loading"
          color="primary"
          @click="save"
          :disabled="!valid"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import ORGANIZATION from "@/services/admin/organization";

const defaultObject = () => ({
  amount: "",
  date: "",
  paymentType: "UNKNOWN",
});

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    paymentTypeList: [
      { text: "Desconhecido", value: "UNKNOWN" },
      { text: "Pix", value: "PIX" },
      { text: "Dinheiro", value: "MONEY" },
      { text: "Cartão", value: "CREDIT_CARD" },
      { text: "Transferência Bancária", value: "BANK_TRANSFER" },
    ],
    paymentTypes: {
      UNKNOWN: "Desconhecido",
      CREDIT_CARD: "Cartão de Crédito",
      PIX: "Pix",
      PAYPAL: "Paypal",
      BANK_TRANSFER: "Transferência Bancária",
      COURTESY: "Cortesia",
      MONEY: "Dinheiro",
    },

    item: false,
    offlineRecall: defaultObject(),
  }),
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let {recall} = await ORGANIZATION.party.offlineRecall.create(
          this.item.party.organizationId,
          this.item.party.id,
          {
            amount: this.floatAmount,
            date: moment(this.offlineRecall.date).toISOString,
            paymentType: this.offlineRecall.paymentType,
            from: this.item.id
          }
        )

        this.loading = false;
        this.$emit("success", recall);
        this.close();
      } catch (e) {
        this.error = e.message || "Erro desconhecido";
        this.loading = false;
      }
    },
    open(item) {
      this.offlineRecall = defaultObject();
      this.item = item;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.error = false;
      this.item = false;
    },
  },

  computed: {
    debt() {
      return this.item ? this.item.sold - this.item.recall : 0;
    },
    hasCredit() {
      return this.floatAmount > this.debt
        ? this.floatAmount - this.debt
        : false;
    },
    floatAmount() {
      return parseFloat(this.offlineRecall.amount);
    },
  },
  filters: {
    currency(value) {
      value = parseFloat(value);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    date(value) {
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
};
</script>

<style></style>
