<template>
  <div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <v-card-text v-else class="px-0">
      <v-data-table
        :headers="headers"
        :items="recallsByUsers"
        :loading="loading"
        :search="search"
        :expanded.sync="expanded"
        single-expand
        show-expand
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar"
              single-line
              hide-details
            />
            <v-btn
              icon
              @click="getRecalls"
              :loading="loading"
              :disabled="blockRefresh"
              class="ml-2"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <base-avatar :size="25" :seed="item.id" :src="item.photo"></base-avatar>
          <span class="ml-2">{{ item.name }}</span>
        </template>
        <template v-slot:item.sold="{ item }">
          {{ item.sold | currency }}
        </template>
        <template v-slot:item.recall="{ item }">
          {{ item.recall | currency }}
        </template>
        <template v-slot:item.total="{ item }">
          {{ (item.sold - item.recall) | currency }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            @click="sendMessage(item)"
            :disabled="loading"
            class="ml-2"
          >
            <v-icon small>mdi-whatsapp</v-icon>
          </v-btn>
          <v-btn icon @click="newRecall(item)" :disabled="loading" class="ml-2">
            <v-icon small>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td class="" :colspan="headers.length">
            <offline-payments-row :data="item"></offline-payments-row>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
    <offline-recall-send-message ref="sendMessage" />
    <offline-recall-modal ref="newRecall" @success="getRecalls" />
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import moment from "moment";
import OfflineRecallSendMessage from "../modals/OfflineRecallSendMessage.vue";
import OfflinePaymentsRow from "./OfflinePaymentsRow.vue";
import OfflineRecallModal from "../modals/OfflineRecallModal.vue";

export default {
  components: {
    OfflineRecallSendMessage,
    OfflinePaymentsRow,
    OfflineRecallModal,
  },
  name: "OfflinePaymentsManagement",
  data: () => ({
    loading: true,
    blockRefresh: false,
    error: false,
    recallsByUsers: [],
    lastUpdate: null,
    search: "",
    expanded: [],
    headers: [
      {
        text: "Promoter",
        value: "name",
        sortable: true,
        align: "start",
      },
      {
        text: "Valor em Vendas",
        value: "sold",
        sortable: true,
      },
      {
        text: "Valor Recolhido",
        value: "recall",
        sortable: true,
      },
      {
        text: "Débito",
        value: "total",
        sortable: true,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center",
      },
      { text: "", value: "data-table-expand" },
    ],
  }),
  methods: {
    async getRecalls() {
      try {
        this.error = null;
        this.loading = true;
        this.blockRefresh = true;

        const { sellers } = await ORGANIZATION.party.offlineRecall.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.recallsByUsers = sellers;

        this.lastUpdate = moment().format("HH:mm:ss");
        this.enableRefresh(10000);
      } catch (e) {
        this.error = e.message || "Erro ao carregar os recebimentos";
        this.enableRefresh(5000);
      } finally {
        this.loading = false;
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
    newRecall(item) {
      this.$refs.newRecall.open({...item, party: this.party});
    },
    sendMessage(item) {
      this.$refs.sendMessage.open(item);
    },
  },
  filters: {
    date(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
    currency(value) {
      value = parseFloat(value || 0);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  mounted() {
    this.getRecalls();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
