<template>
  <div>
    É possível solicitar o reembolso do ingresso
    <template v-if="daysAfterPurchase == 0">com até</template>
    <template v-else>
      até <b>{{ daysAfterPurchase }} dias após a compra</b>, desde que a
      solicitação seja feita com pelo menos</template
    >
    <b>
      {{ hoursBeforeEvent }} horas de antecedência em relação ao início do
      evento</b
    >. Caso a solicitação seja feita fora do prazo, o reembolso será analisado
    pela organização do evento.
  </div>
</template>

<script>
export default {
  computed: {
    daysAfterPurchase() {
      return this.party.refundPolicy.split("-")[0].trim();
    },
    hoursBeforeEvent() {
      return this.party.refundPolicy.split("-")[1].trim();
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>