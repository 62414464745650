<template>
  <v-card outlined :loading="loading">
    <v-card-text class="d-flex flex-wrap justify-space-around px-">
      <div class="text-center px-4" v-if="partyActive">
        <h6 class="mt-2 mb-0">
          {{
            (reducedPayments.today.amount - reducedPayments.today.fee)
              | currency
          }}
          <small>
            ({{ reducedPayments.today.tickets }}
            <v-icon x-small>mdi-ticket</v-icon>)
          </small>
        </h6>
        <span class="text-caption">Vendas Hoje</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">{{ reducedPayments.tickets }}</h6>
        <span class="text-caption">Ingressos Vendidos</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{
            ((reducedPayments.amount - reducedPayments.fee) /
              reducedPayments.tickets)
              | currency
          }}
        </h6>
        <span class="text-caption">Ticket Médio</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">{{ reducedPayments.courtesy || 0 }}</h6>
        <span class="text-caption">Cortesias</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ (reducedPayments.amount - reducedPayments.fee) | currency }}
        </h6>
        <span class="text-caption">Valor Líquido</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({}),
  computed: {
    partyActive() {
      return moment(this.data?.party?.endDate).isAfter(moment());
    },
    approvedPayments() {
      return this.data.payments.filter((p) => p.status === "succeeded");
    },

    reducedPayments() {
      return this.approvedPayments.reduce(
        (acc, p) => {
          const { platformFee } = p;
          const tickets = p.Ticket.length;
          const amount = parseFloat(p.amount);
          const fee = parseFloat(platformFee || 0);

          if (p.payedAt && moment(p.payedAt).isSame(moment(), "day")) {
            acc.today.tickets += tickets;
            acc.today.amount += amount;
            acc.today.fee += fee;
          }

          if (p.paymentMethod === "COURTESY") acc.courtesy += tickets;

          acc.tickets += tickets;
          acc.amount += amount;
          acc.fee += fee;
          return acc;
        },
        {
          tickets: 0,
          amount: 0,
          fee: 0,
          today: {
            tickets: 0,
            amount: 0,
            fee: 0,
          },
          courtesy: 0,
        }
      );
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
