<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
    eager
  >
    <v-card>
      <v-card-title>
        {{
          period?.id
            ? `Editar período ${period.name || period.index + 1}`
            : "Criar período"
        }}
      </v-card-title>
      <v-card-text class="pt-1">
        <v-form v-model="valid" @submit.prevent ref="form" v-if="period">
          <v-text-field
            label="Nome"
            v-model="period.name"
            outlined
            dense
            counter="25"
          />
          <v-textarea
            label="Descrição"
            v-model="period.description"
            outlined
            dense
            rows="3"
            counter="155"
          />

          <div class="d-flex flex-wrap gap-2">
            <v-text-field
              type="datetime-local"
              label="Data inicial"
              v-model="period.startDate"
              outlined
              dense
              :disabled="!party.Period.length || period.index === 0"
              :messages="
                !party.Period.length || period.index === 0
                  ? [
                      'A data do primeiro período obrigatoriamente é a data inicial do evento',
                    ]
                  : ''
              "
              :min="moment(party.date).format('YYYY-MM-DDTHH:mm')"
              :max="moment(party.endDate).format('YYYY-MM-DDTHH:mm')"
              :rules="[
                (v) => !!v || 'Data inicial é obrigatória',
                ...dateRules(),
              ]"
            />
            <v-text-field
              type="datetime-local"
              label="Data final"
              v-model="period.endDate"
              outlined
              dense
              :min="moment(party.date).format('YYYY-MM-DDTHH:mm')"
              :max="moment(party.endDate).format('YYYY-MM-DDTHH:mm')"
              :rules="[
                (v) => !!v || 'Data final é obrigatória',
                (v) =>
                  moment(v).isAfter(period.startDate) ||
                  'Data final deve ser posterior a data inicial',
                ...dateRules(),
              ]"
            />
          </div>

          <v-select
            v-if="ticketGroups"
            v-model="period.TicketGroup"
            :items="ticketGroups"
            item-text="name"
            item-value="id"
            label="Setores com acesso ao período"
            multiple
            outlined
            chips
            deletable-chips
            small-chips
            :rules="[(v) => !!v.length || 'Selecione ao menos um grupo']"
          />
          <v-alert v-else type="info" class="mt-2">
            Você nâo tem permissão para editar os setores deste evento.
          </v-alert>
        </v-form>

        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import TICKET from "@/services/admin/ticket";
import PERIOD from "@/services/admin/party/period";

const defaultPeriod = (party) => {
  var startDate;
  if (!party.Period.length)
    startDate = moment(party.date).format("YYYY-MM-DDTHH:mm");
  else {
    startDate = moment(party.Period[party.Period.length - 1].endDate).format(
      "YYYY-MM-DDTHH:mm"
    );
  }

  return {
    name: "",
    description: "",
    startDate,
    endDate: null,
    TicketGroup: [],
  };
};

export default {
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,
    ticketGroups: false,
    period: null,
  }),

  methods: {
    moment,
    async save() {
      try {
        this.loading = true;
        this.error = false;

        const type = this.period.id ? "update" : "create";

        await PERIOD[type](this.party.organizationId, this.party.id, {
          ...this.period,
          startDate: moment(this.period.startDate).toISOString(),
          endDate: moment(this.period.endDate).toISOString(),
          TicketGroup: this.ticketGroups ? this.period.TicketGroup : undefined,
        });

        this.$emit("success", this.period);
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (e) {
        this.ticketGroups = false;
      }
    },
    open(period) {
      const p = Object.assign({}, defaultPeriod(this.party), period);

      const startDate = moment(p.startDate).format("YYYY-MM-DDTHH:mm");
      p.endDate = p.endDate
        ? moment(p.endDate).format("YYYY-MM-DDTHH:mm")
        : null;
      p.startDate = null;

      p.TicketGroup = p.TicketGroup.map((tg) => tg.id);

      this.period = p;
      this.$nextTick(() => {
        this.period.startDate = startDate;
        this.$refs.form.validate();
      });

      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.$refs.form.reset();
      this.dialog = false;
      this.period = null;
    },

    dateRules() {
      const periods = this.party.Period.filter(
        (p) => p.id !== this.period.id
      ).map((p) => ({
        name: p.name,
        startDate: p.startDate,
        endDate: p.endDate,
      }));

      return [
        (v) =>
          moment(v).isSameOrBefore(this.party.endDate) ||
          `Data deve ser anterior a data final do evento ${moment(
            this.party.endDate
          ).format("DD/MM HH:mm")}`,
        (v) =>
          moment(v).isSameOrAfter(this.party.date) ||
          `Data deve ser posterior a data inicial do evento ${moment(
            this.party.date
          ).format("DD/MM HH:mm")}`,
        ...periods.map(
          (p, i) => (v) =>
            (!moment(v).isBetween(p.startDate, p.endDate) &&
              !moment(v).isSame(p.startDate)) ||
            "Data deve estar fora do período " + (p.name || i + 1)
        ),
      ];
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("period-modal", this.open);
  },
  created() {
    this.getTicketGroups();
  },
  watch: {
    "period.startDate": {
      handler(val, oldVal) {
        if (!val) return;
        if (
          !this.period.endDate ||
          (this.period.endDate && moment(this.period.endDate).isBefore(val))
        ) {
          this.period.endDate = moment(val)
            .add(6, "hours")
            .format("YYYY-MM-DDTHH:mm");
        } else {
          this.period.endDate = moment(this.period.endDate)
            .add(moment(val).diff(moment(oldVal)))
            .format("YYYY-MM-DDTHH:mm");
        }
      },
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
