<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
    eager
  >
    <v-card>
      <v-card-title> Editar vendedores em massa </v-card-title>
      <v-card-text class="pt-1" v-if="sellers.length">
        <v-card
          outlined
          class="pa-2 px-4 rounded-xl d-flex gap-2 mb-4 align-center"
        >
          <h6 class="mb-0">
            {{ sellers.length }} vendedor{{
              sellers.length > 1 ? "es" : ""
            }}
            selecionado{{ sellers.length > 1 ? "s" : "" }}
          </h6>
          <v-spacer />
          <div class="d-flex flex-column align-end justify-center">
            <span v-if="typesCount.user"
              >{{ typesCount.user }} Vendedor{{
                typesCount.user > 1 ? "es" : ""
              }}</span
            >
            <span v-if="typesCount.pos" class="ml-2"
              >{{ typesCount.pos }} PDV{{
                typesCount.seller > 1 ? "s" : ""
              }}</span
            >
          </div>
        </v-card>

        <v-alert :color="!!sellerProfile ? 'success' : 'info'" dense>
          <div class="white--text d-flex justify-space-between">
            <span>
              {{ !!sellerProfile ? "Exibir" : "Não exibir" }} perfil do vendedor
              na página do evento
            </span>
            <v-switch
              v-model="sellerProfile"
              dense
              hide-details
              class="ma-0 pa-0"
            />
          </div>
        </v-alert>

        <v-form v-model="valid" @submit.prevent ref="form">
          <h6>Podem vender</h6>
          <div v-for="(tg, i) in ticketGroups" :key="tg.id">
            <v-divider v-if="i !== 0" class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <h6 class="text-14">{{ tg.name }}</h6>
              <v-btn small text @click.stop="toggleAll(tg.TicketBlock)">
                {{
                  tg.TicketBlock.some(
                    (tb) => !(ticketBlockSeller || []).includes(tb.id)
                  )
                    ? "Marcar todos"
                    : "Desmarcar todos"
                }}
              </v-btn>
            </div>

            <v-row class="mx-0" no-gutters>
              <v-col cols="12" sm="6" v-for="tb in tg.TicketBlock" :key="tb.id">
                <v-checkbox
                  v-model="ticketBlockSeller"
                  :value="tb.id"
                  :label="tb.name"
                  dense
                  multiple
                  hide-details
                  class="mt-0 pt-0"
                />
              </v-col>
            </v-row>
          </div>
        </v-form>

        <v-progress-linear
          v-if="progress !== false"
          :value="progress"
          class="mt-4 rounded-lg"
          height="7"
        />
        
        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="saveAll"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SELLER from "@/services/admin/party/seller";

export default {
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,

    sellers: [],
    ticketBlockSeller: [],
    sellerProfile: false,
    progress: false,
  }),

  methods: {
    toggleAll(ticketBlocks) {
      const sellerTicketBlocks = this.ticketBlockSeller || [];

      ticketBlocks = ticketBlocks.map((p) => p.id);
      const hasAll = ticketBlocks.every((p) => sellerTicketBlocks.includes(p));

      if (hasAll) {
        this.ticketBlockSeller = sellerTicketBlocks.filter(
          (p) => !ticketBlocks.includes(p)
        );
      } else {
        this.ticketBlockSeller = [
          ...new Set(sellerTicketBlocks.concat(ticketBlocks)),
        ];
      }
    },
    async saveAll() {
      try {
        this.loading = true;
        this.error = false;
        this.progress = 0;

        const total = this.sellers.length;
        let count = 0;

        for (const seller of this.sellers) {
          this.progress = Math.round((++count / total) * 100);
          await this.save(seller);
        }

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.error = e.message;
      } finally {
        this.progress = false;
        this.loading = false;
      }
    },
    async save(user) {
      await SELLER.update(this.party.organizationId, this.party.id, user.id, {
        ticketBlocks: this.ticketBlockSeller,
        type: user.type,
      });

      await SELLER.profile[this.sellerProfile ? "update" : "delete"](
        this.party.organizationId,
        this.party.id,
        user.id,
        user.type
      ).catch(() => {});
    },
    open(sellers) {
      this.sellers = sellers;
      this.ticketBlockSeller = [];
      this.sellerProfile = false;

      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.$refs.form.reset();
      this.dialog = false;
      this.seller = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    typesCount() {
      return this.sellers.reduce((acc, s) => {
        acc[s.type] = (acc[s.type] || 0) + 1;
        return acc;
      }, {});
    },
  },
  mounted() {
    this.$parent.$on("massive-seller-modal", this.open);
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
    ticketGroups: {
      type: Array | Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
