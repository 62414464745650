<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0">Vendas por POS</h6>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :items="ticketGroups"
        :headers="headers"
        hide-default-footer
        single-expand
        item-key="deviceHash"
        show-expand
        :expanded.sync="expanded"
      >
        <template v-slot:item.Address.name="{ item }">
          <span class="mb-0 d-block text-overline lh-1"
            >SN:{{ item.Device.serialNumber }}</span
          >
          <span>{{ item.Address.name }}</span>
        </template>
        <template v-slot:item.qnt="{ item }">
          <span> {{ item.qnt }} ingresso{{ item.qnt == 1 ? "" : "s" }}</span>
          <span class="mb-0 d-block">{{ item.totalValue | currency }}</span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pa-2">
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Forma de Pagamento</th>
                    <th class="text-right">Qtde</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="paymentType in item.paymentTypes"
                    :key="paymentType.paymentType"
                  >
                    <td>
                      <b class="d-block">{{ PAYMENTTYPE[paymentType.paymentType].text }}</b>
                    </td>
                    <td class="text-right">
                      <span>
                        {{ paymentType.qnt }} ingresso{{
                          paymentType.qnt == 1 ? "" : "s"
                        }}</span
                      >
                      <span class="mb-0 d-block">{{
                        paymentType.totalValue | currency
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import payment from "@/utils/payment";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      PAYMENTTYPE: payment.paymentType,
      expanded: [],
      headers: [
        {
          text: "POS",
          value: "Address.name",
          align: "left",
        },
        {
          text: "Qtde",
          value: "qnt",
          align: "right",
        },
      ],
    };
  },
  computed: {
    ticketGroups() {
      return Object.values(
        this.data.payments.reduce((acc, p) => {
          if (p.status !== "succeeded") return acc;
          if (!p.PosSession) return acc;
          let posHash = p.PosSession.deviceHash;
          if (!acc[posHash])
            acc[posHash] = {
              ...p.PosSession,
              qnt: 0,
              totalValue: 0,
              paymentTypes: {},
            };
          acc[posHash].qnt += p.Ticket.length;
          acc[posHash].totalValue += p.amount - p.platformFee;

          const paymentType = p.paymentType;
          if (!acc[posHash].paymentTypes[paymentType])
            acc[posHash].paymentTypes[paymentType] = { qnt: 0, totalValue: 0 };
          acc[posHash].paymentTypes[paymentType].qnt += p.Ticket.length;
          acc[posHash].paymentTypes[paymentType].totalValue +=
            p.amount - p.platformFee;

          return acc;
        }, {})
      ).map((v) => ({
        ...v,
        paymentTypes: Object.entries(v.paymentTypes).map(
          ([paymentType, value]) => ({ ...value, paymentType })
        ),
      }));
    },
  },
};
</script>

<style scoped></style>
