<template>
  <div>
    <h6>Pagamentos</h6>
    <v-data-table
      dense
      :headers="headers"
      :items="payments"
      sort-by="createdAt"
    >
      <!-- :items-per-page="payments" -->
      <template #item.id="{ item }"> #{{ item.id | shortId }} </template>
      <template #item.amount="{ item }">
        <div class="d-flex flex-column align-center">
          <span class="font-weight-medium lh-2">
            {{ item.amount | currency }}
          </span>
          <small class="">
            {{ item.tickets.length }} ingresso{{
              item.tickets.length > 1 ? "s" : ""
            }}
          </small>
        </div>
      </template>
      <template #item.createdAt="{ item }">
        {{ item.createdAt | date("DD/MM/YY HH:mm") }}
      </template>
      <template #item.status="{ item }">
        <v-chip label small :color="item.status.color">
          {{ item.status.text }}
        </v-chip>
      </template>
      <template #item.action="{ item }">
        <v-btn icon @click="paymentDetails(item)">
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </template>
      <!-- <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
        </td>
      </template> -->
    </v-data-table>
    <payment-details @refresh="refresh" />
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PaymentDetails from "./PaymentDetails.vue";

export default {
  components: { PaymentDetails },
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ticketStatus: PAYMENT.ticketStatus,
    headers: [
      {
        text: "ID",
        value: "id",
        sortable: false,
        width: 10,
        cellClass: "text-overline lh-1",
      },
      { text: "Valor", value: "amount", align: "center" },
      { text: "Status", value: "status", sortable: false, align: "center" },
      { text: "Data", value: "createdAt", align: "end" },
      { text: "", value: "action" },
    ],
  }),
  computed: {
    payments() {
      return Object.values(
        this.tickets.reduce((acc, { Payment, ...ticket }) => {
          if (!acc[Payment.id]) {
            acc[Payment.id] = {
              ...Payment,
              status: this.calculateStatus(Payment),
              tickets: [],
            };
          }
          acc[Payment.id].tickets.push({
            ...ticket,
          });
          return acc;
        }, {})
      );
    },
  },
  methods: {
    refresh(close = false) {
      this.$emit("refresh", close);
    },
    paymentDetails(payment) {
      this.$emit("payment-details", payment);
    },

    calculateStatus(payment) {
      return {
        value: payment.status,
        ...(this.ticketStatus[payment.status] || {
          color: "secondary",
          text: "Não pago",
        }),
      };
    },
  },
};
</script>

<style></style>
