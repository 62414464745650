<template>
  <div class="mt-3">
    <v-autocomplete
      outlined
      dense
      label="Documentos necessários"
      v-model="policies.requireDocuments"
      :items="documentsList"
      :search-input.sync="documentSearch"
      auto-select-first
      multiple
      hide-details
      class="mb-3"
    ></v-autocomplete>

    <v-textarea
      outlined
      placeholder="Não é permitida a entrada com..."
      dense
      label="Normas de Vestimenta"
      v-model="policies.dressCode"
      rows="3"
      counter="150"
    ></v-textarea>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import AgeGroupText from "../../../global/party/AgeGroupText.vue";

export default {
  components: { AgeGroupText },
  data() {
    return {
      policies: {
        requireDocuments: this.expandDocuments(
          this.party?.requireDocuments || ""
        ),
        dressCode: this.party?.dressCode || "",
      },
      documentSearch: "",
      documents: [
        "Documento com foto",
        "RG",
        "CPF",
        "Comprovante de vacinação",
        "Carteirinha de estudante",
        "Passaporte",
        "Certidão de nascimento",
        "Carteira de trabalho",
        "Comprovante de residência",
        "Comprovante de escolaridade",
        "Comprovante de renda",
      ],
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        requireDocuments: this.compressDocuments(
          this.policies.requireDocuments
        ),
        dressCode: this.policies.dressCode,
      });
    },
    firthLetterUpper(str) {
      if (!str) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    expandDocuments(documents) {
      return documents.split(", ").filter((v) => !!v);
    },
    compressDocuments(documents) {
      return documents.join(", ");
    },
  },
  computed: {
    documentsList() {
      const d = [
        ...this.documents,
        ...this.policies.requireDocuments,
        this.firthLetterUpper(this.documentSearch),
      ]
        .filter((v) => !!v)
        .filter(
          (v, i, a) =>
            a.findIndex((t) => t.toLowerCase() === v.toLowerCase()) === i
        );
      return d;
    },
  },
  watch: {
    "policies.requireDocuments": {
      handler: function (val) {
        this.documentSearch = "";
      },
      deep: true,
    },
    party: {
      handler: function (val, oldVal) {
        this.policies.requireDocuments = this.party?.requireDocuments
          ? expandDocuments(this.party.requireDocuments)
          : [];
        this.policies.dressCode = this.party?.dressCode || "";
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>