<template>
  <div>
    <v-card elevation="0" :loading="loading">
      <v-card-text v-if="party.active">
        <v-select
          v-model="ticketBlock"
          label="Lote"
          outlined
          :items="ticketBlocks"
          :messages="ticketBlockMessage"
        />

        <v-textarea
          v-model="phones"
          outlined
          label="WhatsApp (um por linha)"
          :messages="[
            `${numberPhones} telefone${numberPhones != 1 ? 's' : ''}${
              selectedTicketBlock ? ` de ${ticketBlockMessage}` : ''
            }`,
          ]"
        />
        <div class="d-flex">
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="loading || !ticketBlock || !numberPhones"
            @click="send"
          >
            Enviar
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="error" text border="left">
          Não é possível emitir cortesias para um evento inativo ou encerrado.
        </v-alert>
      </v-card-text>
    </v-card>

    <courtesy-emitter-modal @success="clear" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import CourtesyEmitterModal from "../modals/CourtesyEmitterModal.vue";

export default {
  components: { CourtesyEmitterModal },
  data: () => ({
    loading: false,
    ticketGroups: [],
    ticketBlock: null,
    phones: "",
  }),
  methods: {
    clear() {
      this.ticketBlock = null;
      this.phones = "";
    },
    send() {
      this.$emit("courtesy-emit", {
        ticketBlock: this.ticketBlock,
        phones: this.phones.split("\n").filter((a) => this.phoneIsValid(a)),
      });
    },
    async getTickets() {
      try {
        this.loading = true;
        this.ticketGroups = [];

        const response = await TICKET.canSell(
          this.selectedOrganization.id,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (error) {
        console.log(error);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    phoneIsValid(value) {
      return value.match(/[(][0-9]{2}[)] [0-9]{5}-[0-9]{4}/);
    },
    phoneMask(value) {
      const stringValue = value.toString();
      return stringValue
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "($1) $2")
        .replace(/(\d{5})(\d{4})/, "$1-$2")
        .substring(0, 15);
    },
  },
  watch: {
    phones: {
      handler: function (value) {
        var phones = value.split("\n").map((phone) => phone.trim());
        phones = phones.map((phone) => this.phoneMask(phone));
        this.phones = [...new Set(phones)].join("\n");
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ticketBlocks() {
      const ticketBlocks = [];
      this.ticketGroups
        .filter(({ tableGroupId }) => !tableGroupId)
        .forEach((ticketGroup) => {
          ticketGroup.TicketBlock.forEach((ticketBlock) => {
            ticketBlocks.push({
              text: `${ticketGroup.name} - ${ticketBlock.name}`,
              value: ticketBlock.id,
            });
          });
        });
      return ticketBlocks;
    },
    selectedTicketBlock() {
      const tbId = this.ticketBlock;
      if (!tbId) return null;

      var ticketBlock = null;
      for (const ticketGroup of this.ticketGroups) {
        if (ticketBlock) break;
        for (const tb of ticketGroup.TicketBlock) {
          if (tb.id == tbId) {
            ticketBlock = tb;
            break;
          }
        }
      }
      return ticketBlock;
    },
    ticketBlockMessage() {
      if (!this.selectedTicketBlock) return null;
      const { remaining } = this.selectedTicketBlock.tickets;
      return `${remaining} ingresso${remaining != 1 ? "s" : ""} disponíve${
        remaining != 1 ? "is" : "l"
      }`;
    },
    numberPhones() {
      return this.phones.split("\n").filter((a) => this.phoneIsValid(a)).length;
    },
  },
  mounted() {
    this.getTickets();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
