<template>
  <v-data-table
    :headers="headers"
    :items="!loading ? report.data : []"
    :search="search"
    :expanded.sync="expanded"
    single-expand
    show-expand
    item-key="id"
    sort-by="date"
    sort-desc
  >
    <template v-slot:top>
      <v-text-field
        :disabled="loading"
        v-model="search"
        label="Pesquisar"
        outlined
        dense
        hide-details
        class="pa-2"
      ></v-text-field>
    </template>

    <template v-slot:item.date="{ item }">
      {{ item | date }}
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip small label outlined :color="statusSummary[item.status].color">
        {{ statusSummary[item.status].text }}
      </v-chip>
    </template>
    <template v-slot:item.amount="{ item }">
      <span
        :class="
          item.amount == 0
            ? 'info--text'
            : item.amount < 0
            ? 'warning--text'
            : 'success--text'
        "
      >
        {{ item.amount | currency }}
      </span>
    </template>
    <template v-slot:item.description="{ item }">
      {{ item.description | truncate(60) }}
    </template>
    <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
      <v-btn
        v-if="expandComponents[item.type]"
        icon
        @click="expand(!isExpanded)"
      >
        <v-icon>
          {{ isExpanded ? "mdi-chevron-up" : "mdi-chevron-down" }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td v-if="expandComponents[item.type]" :colspan="headers.length">
        <component :is="expandComponents[item.type]" :object="item.object" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";
import TransactionRow from "./Rows/TransactionRow.vue";
import OfflineRecallsRow from "./Rows/OfflineRecallsRow.vue";
import PaymentsRow from "./Rows/PaymentsRow.vue";
export default {
  data: () => ({
    search: "",
    expanded: [],
    headers: [
      {
        text: "Data",
        value: "date",

        sort: (a, b) => {
          return moment(a).diff(moment(b));
        },
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Valor",
        value: "amount",
      },
      {
        text: "Descrição",
        value: "description",
      },
      { text: "", value: "data-table-expand" },
    ],
    expandComponents: {
      transaction: "TransactionRow",
      offlineRecalls: "OfflineRecallsRow",
      payments: "PaymentsRow",
      memberships: "PaymentsRow",
    },
    statusSummary: {
      PENDING: {
        text: "Pendente",
        color: "info",
      },
      DONE: {
        text: "Efetivada",
        color: "success",
      },
      DELETED: {
        text: "Cancelado",
        color: "error",
      },
    },
  }),
  filters: {
    date(item) {
      let types = {
        payments: "DD/MM/YYYY",
        refundedPayments: "DD/MM/YYYY",
        offlineRecalls: "DD/MM/YYYY",
        refundedOfflinePayments: "DD/MM/YYYY",
      };

      let format = types[item.type] || "DD/MM/YYYY HH:mm:ss";
      return moment(item.date).format(format);
    },
    currency(value) {
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    truncate(value, length) {
      return value.length > length ? value.substring(0, length) + "..." : value;
    },
  },
  components: {
    TransactionRow,
    OfflineRecallsRow,
    PaymentsRow,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    report: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
