<template>
  <div>
    <v-row align="center" align-content="stretch" dense class="mb-2 mx-0">
      <v-col
        v-for="i in headersData"
        :key="i.key"
        align-self="stretch"
        cols="12"
        sm="6"
        md=""
      >
        <v-card
          elevation="0"
          :loading="loading"
          style="height: 100%"
          class="d-flex align-center flex-column rounded-xl"
        >
          <v-card-text class="my-auto">
            <div class="d-flex align-center">
              <v-avatar :color="i.color">
                <v-icon large color="white">{{ i.icon }}</v-icon>
              </v-avatar>
              <div class="ml-3">
                <h6 class="mb-0">
                  {{ i.label }}

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon small class="mb-2" color="grey" v-on="on">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <span>{{ i.helper }}</span>
                  </v-tooltip>
                </h6>
                <div class="caption" v-if="!loading && !!report.resume">
                  <span
                    class="text-subtitle-2"
                    :class="
                      report.resume[i.key] == 0
                        ? 'info--text'
                        : report.resume[i.key] < 0
                        ? 'warning--text'
                        : 'success--text'
                    "
                  >
                    {{ report.resume[i.key] | currency }}
                  </span>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="" order="first" order-md="last" align-self="stretch">
        <v-card class="elevation-0 rounded-xl" style="height: 100%">
          <v-card-text
            class="my-auto pa-1 d-flex flex-column h-full justify-end"
          >
            <div class="text--secondary text-end pr-2">
              <v-btn icon small :disabled="refreshDisabled" @click="getReport">
                <v-icon small>mdi-refresh</v-icon>
              </v-btn>
              <template v-if="last_update">
                Última atualização: {{ last_update }}
              </template>
            </div>
            <div>
              <v-select
                v-model="party"
                :items="mappedParties"
                class="rounded-xl"
                label="Filtrar por evento"
                :append-outer-icon="party ? 'mdi-filter-remove' : ''"
                @click:append-outer="party = null"
                outlined
                dense
                hide-details
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-card elevation="0" class="rounded-lg pa- px-0">
      <component
        :is="viewType + '-report'"
        :loading="loading"
        :report="report"
      />
    </v-card>
    <div
      class="d-flex gap-3 align-center"
      style="position: fixed; bottom: 10px; right: 20px; z-index: 20"
    >
      <v-btn
        @click="viewType = viewType == 'table' ? 'calendar' : 'table'"
        :disabled="loading"
        fab
        color="info"
        dark
      >
        <v-icon>
          mdi-{{ viewType == "table" ? "calendar" : "view-list" }}
        </v-icon>
      </v-btn>
      <v-btn
        @click="addTransaction"
        :disabled="loading"
        color="primary"
        fab
        large
        dark
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
    <transaction-modal @success="getReport" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

import ORGANIZATION from "@/services/admin/organization";
import FINANCIAL from "@/services/admin/financial";
import TableReport from "./Table.vue";
import CalendarReport from "./Calendar.vue";
import TransactionModal from "@/components/admin/financial/modals/TransactionModal.vue";
export default {
  metaInfo: {
    title: "Financeiro",
  },
  data: () => ({
    loading: true,
    party: null,
    viewType: "table",
    p: [],
    report: {},
    last_update: null,
    refreshDisabled: false,
    addList: false,
    headersData: [
      {
        key: "done",
        label: "Caixa Atual",
        icon: "mdi-cash",
        color: "success",
        helper: "Soma das transações efetivadas",
      },
      {
        key: "pending",
        label: "Pagamentos Pendentes",
        icon: "mdi-cash-lock",
        color: "info",
        helper: "Soma das transações pendentes",
      },
      {
        key: "future",
        label: "Pagamentos Futuros",
        icon: "mdi-cash-clock",
        color: "warning",
        helper: "Soma das transações futuras independente do status",
      },
      {
        key: "total",
        label: "Total das Transações",
        icon: "mdi-cash-multiple",
        color: "accent",
        helper: "Soma de todas as transações",
      },
    ],
  }),
  methods: {
    addTransaction() {
      this.$emit("transaction-modal", { partyId: this.party });
    },
    async getReport() {
      try {
        this.loading = true;
        this.refreshDisabled = true;

        const response = await FINANCIAL.report(this.selectedOrganization.id, {
          partyId: this.party || undefined,
        });
        this.report = response;
        this.loading = false;
        this.last_update = moment().format("HH:mm");
        this.enableRefresh(10000);
      } catch (e) {
        console.log(e);
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.refreshDisabled = false;
      }, time);
    },
    selectBg() {
      const selectedParty = this.parties.find((p) => p.id == this.party);
      this.$root.$emit("setImgBg", selectedParty?.cover);
    },
  },
  watch: {
    party: function (val) {
      this.selectBg();
      if (val == this.$route.params.partyId) return;
      if (!val) {
        this.$router.push({
          name: "admin.transactions",
        });
      } else {
        this.$router.push({
          name: "admin.transactions.party",
          params: { partyId: val },
        });
      }
    },
    "$route.params.partyId": function (val) {
      if (!val) return;
      this.party = val;
      this.getReport();
    },
    "$route.name": function (val) {
      if (val != "admin.transactions") return;
      this.party = null;
      this.getReport();
    },
    "selectedOrganization.id": function (val) {
      if (!val) return;
      this.party = null;
      this.getReport();
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization", "parties"]),

    mappedParties() {
      return this.parties.map((party) => ({
        text: party.name,
        value: party.id,
      }));
    },
  },
  components: {
    TableReport,
    TransactionModal,
    CalendarReport,
  },
  mounted() {
    this.party = this.$route.params.partyId || null;
    this.getReport();
    this.selectBg();
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
};
</script>

<style></style>
