<template>
  <div class="py-2">
    <div class="d-flex justify-space-between gap-2 flex-wrap">
      <v-card
        dark
        color="deep-purple darken-4"
        rounded="lg"
        outlined
        class="pa-4 flex-1 card-min-w"
        @click="maxTickets = 1"
      >
        <div class="d-flex align-center mb-2 justify-space-between">
          <h6 class="mb-0">
            <v-icon left>mdi-account-lock</v-icon>
            Anti-Cambistas
          </h6>
          <v-icon>
            mdi-{{ maxTickets === 1 ? "radiobox-marked" : "radiobox-blank" }}
          </v-icon>
        </div>
        <ul>
          <li>Apenas 1 ingresso por conta</li>
          <li>Identificação obrigatória do comprador</li>
        </ul>
      </v-card>
      <v-card
        dark
        color="blue darken-3"
        outlined
        rounded="lg"
        class="pa-4 flex-1 card-min-w"
        @click="maxTickets = 2"
      >
        <div class="d-flex align-center mb-2 justify-space-between">
          <h6 class="mb-0">
            <v-icon left>mdi-ticket-account</v-icon>
            Venda Controlada
          </h6>
          <v-icon>
            mdi-{{ maxTickets > 1 ? "radiobox-marked" : "radiobox-blank" }}
          </v-icon>
        </div>
        <ul>
          <li>Escolha a quantidade máxima de ingressos por conta</li>
          <li>Identificação obrigatória do comprador</li>
        </ul>
        <v-slide-y-transition>
          <v-text-field
            v-show="maxTickets > 1"
            v-model="maxTicketsInput"
            type="number"
            label="Máximo de ingressos por conta"
            outlined
            dense
            min="2"
            max="15"
            class="mt-4"
            dark
            color="white"
            :rules="[
              (v) => !!v || 'Campo obrigatório',
              (v) => v > 1 || 'Mínimo 2 ingressos',
            ]"
            hide-details="auto"
          ></v-text-field>
        </v-slide-y-transition>
      </v-card>
      <v-card
        dark
        color="light-blue darken-2"
        outlined
        rounded="lg"
        class="pa-4 flex-1 card-min-w"
        @click="maxTickets = 0"
      >
        <div class="d-flex align-center mb-2 justify-space-between">
          <h6 class="mb-0">
            <v-icon left>mdi-ticket-outline</v-icon>
            Venda Livre
          </h6>
          <v-icon>
            mdi-{{ maxTickets === 0 ? "radiobox-marked" : "radiobox-blank" }}
          </v-icon>
        </div>
        <ul>
          <li>Sem limite de ingressos por conta</li>
          <li>Identificação opcional do comprador no PDV</li>
        </ul>
        <v-slide-y-transition>
          <v-alert
            v-show="maxTickets === 0"
            dense
            color="white"
            dark
            text
            class="mb-0 mt-2"
          >
            <v-icon left>mdi-alert</v-icon>
            <span class="caption">
              Atenção: Venda livre pode gerar cambistas
            </span>
          </v-alert>
        </v-slide-y-transition>
      </v-card>
    </div>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

export default {
  components: {},
  data() {
    return {
      maxTicketsInput: Math.max(this.party?.maxTickets || 2, 2),
      maxTickets: Number.isInteger(this.party?.maxTickets)
        ? this.party.maxTickets
        : 1,
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        maxTickets: parseInt(this.ticketsPerUser),
      });
    },
  },
  computed: {
    ticketsPerUser() {
      if (this.maxTickets > 1) return this.maxTicketsInput || 2;
      return this.maxTickets;
    },
  },
  watch: {
    maxTickets: {
      handler: function (val, oldVal) {
        this.maxTicketsInput = Math.max(this.maxTicketsInput || val || 0, 2);
      },
    },
    party: {
      handler: function (val, oldVal) {
        this.maxTickets = this.party?.maxTickets || 0;
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
.card-min-w {
  cursor: pointer;
  min-width: 200px;
}
</style>
