<template>
  <div class="py-2">
    <p class="mb-0">Transferencias</p>
    <v-list dense>
      <v-list-item
        v-for="(transfer, i) in sortedTicketTransfer"
        :key="transfer.id"
      >
        <div class="d-flex justify-space-between align-center flex-grow-1">
          <div class="d-flex gap-2 align-center">
            <baseAvatar
              :size="20"
              :src="transfer.from.photo"
              :seed="transfer.from.id"
            ></baseAvatar>
            <span>{{ transfer.from.name || "Conta não criada" }}</span>
            <v-tooltip v-if="!i" top>
              <template v-slot:activator="{ on }">
                <v-icon small v-on="on">mdi-account-cash</v-icon>
              </template>
              <span>Comprador original</span>
            </v-tooltip>
          </div>
          <div class="d-flex flex-column">
            <v-icon small>mdi-arrow-right</v-icon>
            <small class="lh-1">
              {{ transfer.createdAt | date("DD/MM/YY HH:mm") }}
            </small>
          </div>
          <div class="d-flex gap-2 align-center">
            <baseAvatar
              :size="20"
              :src="transfer.to.photo"
              :seed="transfer.to.id"
            ></baseAvatar>
            <span>{{ transfer.to.name || "Conta não criada" }}</span>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  computed: {
    sortedTicketTransfer() {
      return this.ticketTransfer.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
    },
  },
  props: {
    ticketTransfer: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>