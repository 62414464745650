<template>
  <div class="mt-2">
    <v-row>
      <v-col cols="12" sm="6">
        <v-img
          v-if="url"
          :src="url"
          aspect-ratio="1"
          contain
          class="rounded"
          height="350px"
        ></v-img>
        <v-alert v-else type="info">
          <p>
            O mapa aparecerá na página de vendas do online do ingresso e nos
            PDVs.
          </p>
          <p class="mb-0">
            Formato recomendado: proporção 4:3 e mínimo de 800x600px, peso
            máximo de 1MB.
          </p>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="6">
        <file-drop
          @change="loadFile"
          :disabled="!!loading"
          accept=".png,.jpg,.jpeg,.webp,"
          label="Arraste o arquivo do mapa aqui"
        />
        <p v-if="url" class="mb-0 px-2 text-center">
          Formato recomendado: proporção 4:3 e mínimo de 800x600px, peso máximo
          de 1MB.
        </p>
      </v-col>
      <v-col cols="12">
        <div>
          <v-btn
            @click="saveMap"
            color="success"
            :disabled="!file"
            :loading="loading"
          >
            Salvar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

import FileDrop from "../../global/FileDrop.vue";
export default {
  components: { FileDrop },
  mounted() {},
  data: () => ({
    mapURL: null,
    file: null,
    loading: false,
  }),
  watch: {},
  methods: {
    async saveMap() {
      this.loading = true;
      try {
        let response = await PARTY.staticMap(
          this.party.organizationId,
          this.party.id,
          this.file
        );
        this.$emit("refresh");
        this.file = null;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    loadFile(file) {
      this.file = file;
      this.saveMap();
    },
  },
  computed: {
    url() {
      if (this.file) return URL.createObjectURL(this.file);

      return this.mapURL || this.party.imageMap;
    },
  },

  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
