<template>
  <v-row id="form-party-privacy" align="stretch" justify="center">
    <v-col cols="12" sm="6">
      <v-card
        class="h-full rounded-lg pa-4 px-5"
        v-ripple
        :color="
          [
            color.private.base,
            isPrivate ? color.private.selected : color.private.notSelected,
          ].join(' ')
        "
        :style="{
          opacity: isPrivate ? 1 : 0.7,
        }"
        @click="isPrivate = true"
      >
          <div class="d-flex align-center mb-4">
            <v-icon left>mdi-account-lock</v-icon>
            <span class="text-button font-weight-bold lh-1">Evento Privado</span>
            <v-spacer />
            <v-radio-group v-model="isPrivate" class="mt-0" hide-details>
              <v-radio
                :value="true"
                hide-details
                name="isPrivate"
                color="white"
                class="ma-0"
              ></v-radio>
            </v-radio-group>
          </div>
          <ul class="font-weight-medium">
            <li>Evento não divulgado no aplicativo</li>
            <li>Acesso restrito à página de vendas aos convidados</li>
            <li>
              Convidados não conseguirão compartilhar o link com outras pessoas
            </li>
          </ul>
      </v-card>
    </v-col>
    <v-col cols="12" sm="6">
      <v-card
        class="h-full rounded-lg pa-4 px-5"
        v-ripple
        :color="
          [
            color.public.base,
            !isPrivate ? color.public.selected : color.public.notSelected,
          ].join(' ')
        "
        :style="{
          opacity: !isPrivate ? 1 : 0.5,
        }"
        :outlined="!!isPrivate"
        @click="isPrivate = false"
      >
          <div class="d-flex align-center mb-4">
            <v-icon left>mdi-account-group</v-icon>
            <span class="text-button font-weight-bold lh-1">Evento Público</span>
            <v-spacer />
            <v-radio-group v-model="isPrivate" class="mt-0" hide-details>
              <v-radio
                :value="false"
                hide-details
                name="isPrivate"
                color="white"
                class="ma-0"
              ></v-radio>
            </v-radio-group>
          </div>
          <ul class="font-weight-medium">
            <li>Evento divulgado no aplicativo</li>
            <li>Acesso livre à página de vendas</li>
            <li>Convidados poderão compartilhar o link com outras pessoas</li>
            <!-- <li>Links para acesso de ingressos ocultos</li> -->
          </ul>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PARTY from "@/services/admin/party";

export default {
  data() {
    return {
      isPrivate: this.party?.private,
      colors: {
        dark: {
          private: {
            base: "indigo",
            selected: "",
            notSelected: "lighten-2",
          },
          public: {
            base: "success",
            selected: "",
            notSelected: "lighten-2",
          },
        },
        light: {
          private: {
            base: "indigo",
            selected: "accent-2",
            notSelected: "lighten-1",
          },
          public: {
            base: "success",
            selected: "accent-2",
            notSelected: "lighten-1",
          },
        },
      },
    };
  },

  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        private: !!this.isPrivate,
      });
    },
  },
  computed: {
    color() {
      return this.colors[this.$vuetify.theme.dark ? "dark" : "light"];
    },
  },
  watch: {
    party: {
      handler: function (val) {
        this.isPrivate = val.private;
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
