<template>
  <v-dialog
    v-model="dialog"
    :width="step == 3 ? 400 : 900"
    persistent
    scrollable
  >
    <v-card :loading="loading">
      <v-card-title class="pb-2 pt-3 px-6">
        <span class="text-16">
          {{ !step == 3 ? "Adicionar grupo" : "Conectar grupo" }}
        </span>
      </v-card-title>
      <v-card-text class="pa-0 ma-0">
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header class="elevation-0" style="height: 50px">
            <template v-for="(stp, i) in steps">
              <v-divider v-if="i" :key="`divider-` + i" />
              <v-stepper-step
                :key="`step-` + i"
                :complete="step > i + 1"
                :step="i + 1"
                class="py-0"
              >
                {{ stp }}
              </v-stepper-step>
            </template>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-row align="stretch" dense class="mx-0">
                <v-col
                  v-for="[key, value] in Object.entries(types)"
                  :key="key"
                  cols="12"
                  md="4"
                >
                  <v-card
                    v-ripple
                    dark
                    :color="
                      type === key
                        ? `${value.color} accent-4`
                        : `${value.color} darken-3`
                    "
                    :style="{
                      opacity: type === key ? 1 : 0.5,
                    }"
                    :outlined="type === key"
                    height="100%"
                    :disabled="value.future"
                    rounded="xl"
                    @click="type = key"
                    class="d-flex flex-column"
                  >
                    <v-card-title>
                      <v-icon left>{{ value.icon }}</v-icon>
                      {{ value.label }}
                      <v-spacer />
                      <v-radio-group
                        v-model="type"
                        class="mt-0 pt-0"
                        hide-details
                      >
                        <v-radio
                          color="white"
                          :value="key"
                          hide-details
                          name="groupType"
                        ></v-radio>
                      </v-radio-group>
                    </v-card-title>
                    <v-card-text
                      class="flex-grow-1 d-flex flex-column justify-center"
                    >
                      <ul>
                        <li
                          v-for="(item, i) in typesFeatures[key]"
                          :key="`${key}-${i}`"
                        >
                          {{ item.label }}
                          {{ item.future && "(Em breve)" }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="2">
              <component v-model="options" :is="type" :party="party" />
            </v-stepper-content>
            <v-stepper-content step="3" class="py-0">
              <div
                v-if="whatsappGroup"
                class="d-flex flex-column align-center"
                style="gap: 0.5rem"
              >
                <qrcode-vue
                  class="pa-4 white"
                  :value="url"
                  size="250"
                  level="M"
                  :margin="5"
                  ref="qrCode"
                  render-as="svg"
                />
                <v-btn color="primary" block width="250" large @click="openUrl">
                  <v-icon>mdi-whatsapp</v-icon>
                  Adicionar grupo
                </v-btn>
                <v-alert dense text type="info" class="mb-0">
                  <b>Não se esqueça!</b> Você precisa adicionar a TicketMe no
                  grupo antes de conectar.
                </v-alert>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Cancelar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="step == 2"
          class="ml-1"
          text
          :disabled="loading"
          @click="step = 1"
        >
          Voltar
        </v-btn>
        <v-btn
          v-if="step == 1"
          class="ml-1"
          color="primary"
          :loading="loading"
          @click="step = 2"
        >
          Próximo
        </v-btn>
        <v-btn
          v-if="step == 2"
          class="ml-1"
          color="success"
          :loading="loading"
          @click="getCode"
        >
          Conectar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import WHATSAPPGROUP from "@/services/admin/party/whatsappGroup";
import QrcodeVue from "qrcode.vue";
import WhatsappGroupTypes from "@/definitions/WhatsappGroupTypes";

import AdminOptions from "@/components/admin/party/whatsappGroup/options/AdminOptions.vue";
import GuestsOptions from "@/components/admin/party/whatsappGroup/options/GuestsOptions.vue";
import EmployeeOptions from "@/components/admin/party/whatsappGroup/options/EmployeeOptions.vue";

export default {
  components: {
    QrcodeVue,
    ADMIN: AdminOptions,
    GUESTS: GuestsOptions,
    EMPLOYEE: EmployeeOptions,
  },

  data: () => ({
    dialog: false,
    loading: false,
    error: false,

    type: "GUESTS",
    options: null,

    whatsappGroup: null,
    step: 1,
    steps: ["Tipo", "Opções", "Conectar"],
    types: WhatsappGroupTypes,
    typesFeatures: {
      GUESTS: [
        { label: "Os membros podem ver o evento privado mesmo sem ingresso" },
        { label: "Permita que convidados entrem no grupo" },
        { label: "Restrinja a venda de um setor apenas para membros" },
        // {
        //   label: "Compartilhe publicações do feed direto no grupo",
        //   future: true,
        // },
      ],
      EMPLOYEE: [
        { label: "Aviso lote próximo do fim" },
        { label: "Aviso sobre virada de lotes" },
        { label: "Ranks de vendas semanais", future: true },
        // { label: "Lembre de publicações no Instagram", future: true },
      ],
      ADMIN: [
        { label: "Aviso lote próximo do fim" },
        { label: "Aviso sobre virada de lotes" },
        { label: "Aviso sobre pedidos de reembolso" },
        // { label: "Relatórios semanais", future: true },
        // { label: "Meta de vendas atingida", future: true },
      ],
    },
  }),

  methods: {
    openUrl() {
      window.open(this.url, "_blank");
    },
    open() {
      this.step = 1;
      this.dialog = true;
      this.type = "GUESTS";
      this.whatsappGroup = null;
    },
    close() {
      if (this.step == 3) this.$emit("update");
      this.dialog = false;
      this.type = "GUESTS";
      this.step = 1;
      this.whatsappGroup = null;
    },
    async getCode() {
      try {
        this.loading = true;
        this.error = false;

        const { whatsappGroup } = await WHATSAPPGROUP.create(
          this.selectedOrganization.id,
          this.party.id,
          { type: this.type, options: this.options }
        );

        this.whatsappGroup = whatsappGroup;
        this.step = 3;
      } catch (e) {
        this.error = e.message || "Erro ao salvar atração";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    url() {
      if (!this.whatsappGroup) return null;
      const text =
        "Estou adicionando esse grupo no meu evento no sistema TicketMe. \nPGL-" +
        this.whatsappGroup.id.substr(0, 8);
      return new URL(`https://api.whatsapp.com/send?text=${text}`).href;
    },
  },
  watch: {
    type: {
      handler() {
        this.options = null;
      },
      immediate: true,
    },
  },
  mounted() {
    this.$parent.$on("whatsapp-group-add", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
