<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
    :persistent="loading"
  >
    <v-card v-if="!!ticket && !succeeded">
      <v-card-title class="headline"> Reembolsar Ingresso </v-card-title>
      <v-card-text class="pb-2">
        <v-form
          v-model="valid"
          ref="transferForm"
          class="mb-4"
          @submit="refund"
        >
          <h6 class="text-center">Reembolso</h6>
          <v-btn-toggle
            v-model="type"
            mandatory
            width="100%"
            class="d-flex mb-4"
          >
            <v-btn text style="width: 50%"> Total </v-btn>
            <v-btn text style="width: 50%"> Parcial </v-btn>
          </v-btn-toggle>
          <v-scroll-y-transition leave-absolute>
            <v-text-field
              v-if="type === 1"
              v-model="amount"
              label="Valor"
              type="number"
              step="0.01"
              min="0"
              :max="ticket.Payment.amount"
              required
              outlined
              :rules="[
                (v) => !!v || 'Valor é obrigatório',
                (v) =>
                  v <= ticket.Payment.amount ||
                  'Valor não pode ser maior que o valor do ingresso',
                (v) => v > 0 || 'Valor deve ser maior que 0',
              ]"
            ></v-text-field>
          </v-scroll-y-transition>
        </v-form>
        <v-alert type="info" class="mb-0" border="left">
          Atenção: apos reembolsar um ingresso não será possível utilizar,
          transferir ou reembolsar novamente.
        </v-alert>
        <v-alert type="error" v-if="error" class="mt-2 mb-0" border="left">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          color="primary"
          @click="refund"
          block
          :disabled="!valid"
          :loading="loading"
          class="mb-2"
        >
          Reembolsar
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
          :disabled="loading"
          block
          class="ml-0"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="succeeded">
      <v-card-text>
        <div class="text-center pt-8">
          <v-avatar class="mb-4" size="60" color="success lighten-4">
            <v-icon x-large color="success darken-2">mdi-check</v-icon>
          </v-avatar>
          <h6>{{ result.message }}</h6>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn color="primary" text @click="close(true)" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TICKET from "@/services/admin/ticket";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    ticket: null,
    loading: false,
    valid: false,
    error: false,
    succeeded: false,
    result: null,
    type: 0,
    amount: 0,
  }),
  props: {
    party: {
      type: Object | null,
      required: true,
    },
  },
  methods: {
    async refund() {
      try {
        this.loading = true;
        this.error = false;
        const response = await TICKET.refund(
          this.selectedOrganization.id,
          this.party.id,
          this.ticket.id,
          {
            amount: this.type
              ? parseFloat(this.amount)
              : this.ticket.Payment.amount,
          }
        );
        this.result = response;
        this.succeeded = true;
        this.loading = false;
        this.$emit("success");
        this.$root.$emit("refund-ticket:success");
      } catch (error) {
        this.error = error.message || "Erro ao transferir ingresso";
        this.check = false;
        this.loading = false;
      }
    },
    open(ticket) {
      this.ticket = ticket;
      this.null = null;
      this.succeeded = false;
      this.error = false;
      this.type = 0;
      this.amount = ticket.Payment.amount;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("refund-ticket", this.open);
  },
};
</script>

<style></style>
