<template>
  <div>
    <div
      class="text-center rounded"
      :class="padding"
      style=" transition: all 0.5s ease"
      outlined
      :style="dropAreaStyle"
      @click="clickInput"
      @drop="dropHandler"
      @dragenter="drag(true)"
      @dragleave="drag(false)"
      @dragover.prevent
    >
      <template v-if="error">
        <span> {{ error }}</span>
      </template>
      <template v-else-if="!hover"> {{ label }} </template>
      <template v-else>Solte os arquivos aqui</template>
    </div>
    <input
      ref="input"
      type="file"
      :multiple="multiple"
      :accept="accept"
      style="display: none"
      @change="changeInput"
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    hover: false,
    error: false,
  }),
  methods: {
    clearError(ms) {
      setTimeout(() => {
        this.error = false;
      }, ms);
    },
    dropHandler(e) {
      e.preventDefault();
      if (this.disabled) return;
      this.hover = false;
      var files = Array.from(e.dataTransfer.files);

      if (this.accept) {
        const accepts = this.accept.split(",").map((a) => {
          const type = a.includes("/*") ? "type" : "subtype";
          return {
            type,
            value: a.trim().replace("/*", ""),
          };
        });

        files = files.filter((file) => {
          const { type, name } = file;
          return accepts.some((a) => {
            if (a.type === "type") return type.includes(a.value);
            else return name.endsWith(a.value);
          });
        });

        if (files.length === 0) {
          this.error = "Arquivo não suportado";
          this.clearError(3000);
          return;
        }
      }
      this.$emit("change", this.multiple ? files : files[0]);
    },
    drag(hover) {
      this.hover = hover;
    },
    clickInput() {
      if (this.disabled) return;
      this.$refs.input.click();
    },
    changeInput(e) {
      const files = Array.from(e.target.files);
      this.$emit("change", this.multiple ? files : files[0]);
      this.$refs.input.value = "";
    },
  },
  computed: {
    dropAreaStyle() {
      if (this.disabled)
        return {
          "background-color": "lightgray",
          "border-color": "lightgray",
          "border-width": "3",
          "border-style": "dashed",
          color: "gray",
        };

      const { hover, error } = this;
      return {
        "border-color": hover ? "var(--v-primary-base)" : error ? "red" : "",
        "border-width": hover ? "5" : "3",
        "border-style": hover ? "solid" : "dashed",
        cursor: "pointer",
        color: hover ? "var(--v-primary-base)" : error ? "red" : "",
      };
    },
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: null,
    },
    padding: {
      type: String,
      default: "pa-12",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Arraste os arquivos para cá ou clique para selecionar",
    },
  },
};
</script>

<style></style>
