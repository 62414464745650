<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6>Ingressos Reembolsados</h6>
      <v-btn x-small text color="primary" v-if="hasPermission(128)" @click="goToRefound">
        Ver reembolsos
      </v-btn>
    </v-card-title>
    <v-card-text class="text-center">
      <v-avatar color="secondary lighten-2" size="50">
        <v-icon class="display-1">mdi-ticket-confirmation</v-icon>
      </v-avatar>
      <h4 class="mb-0">
        {{ refundedTicketsCount }} ingresso{{
          refundedTicketsCount == 1 ? "" : "s"
        }}
      </h4>
      <h6>
        {{ refundedTicketsValue | currency }}
        <sup class="font-weight-thin"> {{ refundedTicketsPercentage }}% </sup>
      </h6>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";

export default {
  data: () => ({
    PAYMENT,
  }),
  methods: {
    goToRefound() {
      this.$router.push({
        hash: "#refound",
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),

    validTickets() {
      return this.data.tickets.filter(
        (ticket) => !ticket.Payment.status != "canceled"
      );
    },
    refundedTickets() {
      return this.validTickets.filter(
        (ticket) => ticket.Payment.status == "refunded"
      );
    },
    refundedTicketsCount() {
      return this.refundedTickets.length;
    },
    refundedTicketsValue() {
      return this.refundedTickets.reduce(
        (acc, ticket) => acc + ticket.Payment.amount,
        0
      );
    },
    validTicketsValue() {
      return this.validTickets.reduce(
        (acc, ticket) => acc + ticket.Payment.amount,
        0
      );
    },
    refundedTicketsPercentage() {
      return ((this.refundedTicketsValue / this.validTicketsValue || 1) * 100)
        .toFixed(1)
        .replace(/[.,]0$/, "");
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
