var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{staticClass:"px-1 pb-2",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-alert',{attrs:{"type":"info","text":"","border":"left"}},[_vm._v(" Defina um preço diferenciado para cada tipo de membro. "),_c('br'),_c('small',{staticClass:"text-14"},[_vm._v(" Cada filiado só poderá comprar "),_c('b',[_vm._v(" um ingresso com desconto por evento. ")])])]),_c('div',{staticClass:"d-flex flex-column gap-3 px-1"},_vm._l((_vm.activeMemberships),function(membership){return _c('div',{key:membership.id,staticClass:"d-flex gap-2"},[_c('v-chip',{staticClass:"pa-5 text-13 font-weight-bold",style:({
          background: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(${membership.backgroundImg})`,
          backgroundSize: 'cover',
          border: `1px solid ${membership.backgroundColor || '#000'}`,
          boxShadow: `0 0 10px  ${
            membership.backgroundColor || 'rgba(0,0,0,.2)'
          }`,
        }),attrs:{"color":membership.backgroundColor,"text-color":"white","label":"","small":""}},[_vm._v(" "+_vm._s(membership.name)+" ")]),_c('v-text-field',{attrs:{"value":_vm.membershipPrice[membership.id],"outlined":"","dense":"","hide-details":"auto","placeholder":_vm.ticketBlock.price + ` (valor padrão)`,"prefix":`R$ `,"type":"number","step":"0.01","min":"3","max":_vm.ticketBlock.price,"rules":[
          (v) => !v || parseFloat(v) >= 3 || 'O preço mínimo é R$ 3,00',
          (v) =>
            !v ||
            parseFloat(v) < parseFloat(_vm.ticketBlock.price) ||
            'O preço não pode ser maior que o valor do lote',
        ]},on:{"input":function($event){return _vm.setMembershipPrice(membership.id, $event)}}})],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }