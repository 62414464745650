<template>
  <div>
    <v-dialog v-model="isOpen" width="700" @close="close" :persistent="loading">
      <v-card v-if="!!phones" :loading="loading">
        <v-card-text>
          <v-alert v-if="error" type="error">
            {{ error }}
          </v-alert>
          <v-list v-else>
            <v-list-item
              v-for="(result, i) in results ? results : phones"
              :key="i"
            >
              <v-list-item-content>
                
                <v-list-item-title>
                  <v-progress-circular size="15" indeterminate v-if="loading" />
                  <v-icon v-else-if="result.error" color="error">mdi-alert-circle</v-icon>
                  <v-icon v-else color="success">mdi-check-circle</v-icon>
                
                  {{ result.phone || result }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="!loading">
                  {{ result.error || "Enviado com sucesso" }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="secondary" text :disabled="loading" @click="close">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
export default {
  data: () => ({
    isOpen: false,
    loading: false,
    phones: [],
    ticketBlock: null,
    error: false,
    results: false,
  }),

  methods: {
    open(data) {
      this.results = false;
      this.error = false;
      this.ticketBlock = data.ticketBlock;
      this.phones = data.phones;
      this.isOpen = true;
      this.send();
    },
    close() {
      if (this.loading) return;
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.$emit("success");
    },
    async send() {
      try {
        this.loading = true;
        const response = await TICKET.sellLot(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          {
            type: "courtesy",
            ticketBlock: this.ticketBlock,
            users: this.phones.map((phone) => ({ phone })),
          }
        );
        this.results = response.users;
      } catch (e) {
        this.error = e.message || "Erro ao enviar";
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("courtesy-emit", this.open);
  },
};
</script>

<style></style>
