<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Galeria</h6>
      <v-btn
        color="primary"
        @click="addPhotos"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon left :small="$vuetify.breakpoint.mdAndDown"> mdi-plus </v-icon>
        fotos
      </v-btn>
    </div>
    <div>
      <v-alert
        :loading="loading"
        :color="loading ? 'info' : error ? 'error' : 'primary'"
        text
        class="my-4 mb-2"
        dense
      >
        <template v-if="loading && (!gallery || !gallery.length)">
          Carregando fotos...
        </template>
        <template v-else-if="error">
          {{ error }}
        </template>
        <template v-else-if="!!gallery.length">
          {{ gallery.length }} foto{{ gallery.length != 1 ? "s" : "" }}
        </template>
        <template v-else>
          <p class="text-18 font-weight-bold mb-0">
            Seu evento ainda não tem nenhuma foto
          </p>
          <p class="mb-0">
            As fotos são exibidas na página do evento{{
              !party.private
                ? ", na galeria e na página inicial"
                : " apenas para convidados"
            }}.
          </p>
        </template>
      </v-alert>
      <gallery v-if="!!gallery.length" :gallery="gallery || []">
        <template #default="{ item, index }">
          <v-hover v-slot="{ hover }">
            <div
              style="
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                position: absolute;
                transition: all 0.3s ease;
              "
              class="d-flex flex-column justify-center align-center rounded"
              :style="
                hover
                  ? 'background: rgba(0,0,0, 0.8)'
                  : 'background: rgba(0,0,0, 0)'
              "
            >
              <template v-if="hover">
                <div>
                  <v-btn
                    icon
                    :disabled="loading"
                    :loading="deleteO.index === index && deleteO.loading"
                    @click="removePhoto(index)"
                    color="white"
                  >
                    <v-icon
                      color="error"
                      v-if="deleteO.index === index && deleteO.confirm"
                    >
                      mdi-delete-alert
                    </v-icon>
                    <v-icon
                      color="error"
                      v-else-if="deleteO.index === index && deleteO.error"
                    >
                      mdi-alert-octagon
                    </v-icon>
                    <v-icon color="white" v-else> mdi-delete </v-icon>
                  </v-btn>
                  <!-- <v-icon
                    class="ml-6"
                    color="white"
                    :disabled="loading"
                    @click="editPhoto(index)"
                  >
                    mdi-pencil
                  </v-icon> -->
                </div>
                <v-icon
                  class="mt-4"
                  color="white"
                  :disabled="loading"
                  @click="download(item)"
                >
                  mdi-download
                </v-icon>
              </template>
            </div>
          </v-hover>
        </template>
      </gallery>
    </div>
    <gallery-upload-photo @success="getImages" />
    <gallery-photo-edit @success="getImages" />
  </div>
</template>

<script>
import GalleryUploadPhoto from "./modal/GalleryUploadPhoto.vue";
import GalleryPhotoEdit from "./modal/GalleryPhotoEdit.vue";
import GALLERY from "@/services/admin/party/gallery";
import Gallery from "../../global/Gallery.vue";

export default {
  components: { GalleryUploadPhoto, GalleryPhotoEdit, Gallery },
  data: () => ({
    loading: false,
    error: false,
    gallery: [],
    deleteO: {
      index: null,
      loading: false,
      error: false,
      confirm: false,
    },
  }),
  methods: {
    editPhoto(index) {
      this.$emit("edit-photo", {
        gallery: this.gallery[index],
        party: this.party,
      });
    },
    async removePhoto(index) {
      if (this.deleteO.index != index) {
        this.deleteO.index = index;
        this.deleteO.confirm = true;
        this.deleteO.loading = false;
        this.deleteO.error = false;
        return;
      }

      try {
        this.deleteO.index = index;
        this.deleteO.confirm = false;
        this.deleteO.loading = true;
        this.loading = true;

        await GALLERY.destroy(
          this.party.organizationId,
          this.party.id,
          this.gallery[index].id
        );
        this.gallery.splice(index, 1);
      } catch (error) {
        this.deleteO.error = true;
        console.log(error);
      } finally {
        this.deleteO.loading = false;
        this.loading = false;
      }
    },
    download(item) {
      const { url } = item;
      window.open(url, "_blank", "noopener");
    },
    addPhotos() {
      this.$emit("upload-photo", {
        party: this.party,
      });
    },
    async getImages() {
      try {
        this.loading = true;
        const { gallery } = await GALLERY.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.gallery = gallery;
      } catch (error) {
        this.error = error.message || "Erro ao carregar as fotos";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getImages();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
