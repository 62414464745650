<template>
  <v-dialog v-model="dialog" max-width="600" @click:outside="close">
    <v-card rounded="lg" v-if="payment">
      <v-card-title class="text-16 pb-1 justify-space-between">
        <span class="d-flex align-center gap-1 lh-1">
          Compra
          <v-chip small class="text-overline font-weight-bold">
            #{{ payment.id | shortId }}
          </v-chip>
        </span>
        <v-btn
          x-small
          depressed
          rounded
          :loading="loading"
          @click="refreshTicketPayment"
          :color="payment.status.color"
        >
          <v-icon left small>{{ payment.status.icon }}</v-icon>
          {{ payment.status.text }}
        </v-btn>
        <!-- <v-chip small :color="payment.status.color">
          <v-icon left small>{{ payment.status.icon }}</v-icon>
          {{ payment.status.text }}
        </v-chip> -->
        <!-- <span> {{ payment.amount | currency }}</span> -->
      </v-card-title>
      <v-card-text class="d-flex flex-column gap-y-3 pt-2">
        <v-card
          outlined
          class="d-flex justify-space-around flex-wrap gap-x-6 gap-y-3 pa-3"
        >
          <div class="text-center">
            <p class="mb-0 lh-1">Data da compra</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.createdAt | date("DD/MM/YYYY HH:mm") }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Meio de pagamento</p>
            <p class="mb-0 font-weight-bold">
              {{ PAYMENT.paymentMethod[payment.paymentMethod].text }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Forma de pagamento</p>
            <p class="mb-0 font-weight-bold">
              {{ PAYMENT.paymentType[payment.paymentType].text }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Promoter</p>
            <p class="mb-0 font-weight-bold">
              {{ payment.Seller?.name || "Online" }}
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor Pago</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.clientFee) | currency }}
              <span v-if="payment.clientFee > 0" class="text-caption">
                + {{ payment.clientFee | currency }}
              </span>
            </p>
          </div>
          <div class="text-center">
            <p class="mb-0 lh-1">Valor liquido</p>
            <p class="mb-0 font-weight-bold">
              {{ (payment.amount - payment.platformFee) | currency }}
            </p>
          </div>
        </v-card>

        <v-card outlined class="pa-3">
          <h6>Ingressos</h6>
          <v-data-table
            :headers="headers"
            :expanded.sync="expanded"
            :items="payment.tickets"
            :items-per-page="5"
            :hide-default-footer="true"
            item-key="id"
            selectable-key="id"
            order-by="TicketBlock.TicketGroup.name"
            single-expand
            show-expand
          >
            <template #item.id="{ item }">
              <span class="text-overline lh-1">#{{ item.id | shortId }} </span>
            </template>
            <template #item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="
                      hasPermission(128) &&
                      !PAYMENT.status_db_groups.canceled.includes(
                        payment.status.value
                      )
                    "
                    @click="transfer(item)"
                    icon
                    v-on="on"
                    :disabled="loading"
                  >
                    <v-icon> mdi-account-arrow-right </v-icon>
                  </v-btn>
                </template>
                <span>Transferir</span>
              </v-tooltip>
            </template>
            <template #item.data-table-expand="{ item, on, expand }">
              <v-btn
                v-if="item.TicketTransfer.length"
                icon
                v-on="on"
                @click="expand"
              >
                <v-icon> mdi-swap-horizontal </v-icon>
              </v-btn>
            </template>
            <template #expanded-item="{ item, headers }">
              <td class="" :colspan="headers.length">
                <GuestTicketTransfer :ticketTransfer="item.TicketTransfer" />
              </td>
            </template>
          </v-data-table>
        </v-card>

        <v-row dense class="mx-0">
          <template v-if="payment.status.value === 'succeeded'">
            <v-col v-if="hasPermission(64)" cols="12" sm="" class="pt-0 pb-2">
              <v-btn
                color="info"
                @click="resend(payment.tickets[0])"
                :loading="loading"
                :disabled="ticketResent"
                small
                block
              >
                <v-icon small left>mdi-invoice-send</v-icon>
                <span v-if="!ticketResent">
                  Reenviar ingresso{{ payment.tickets.length > 1 ? "s" : "" }}
                </span>
                <span v-else>
                  Ingresso{{
                    payment.tickets.length > 1 ? "s" : ""
                  }}
                  reenviado{{ payment.tickets.length > 1 ? "s" : "" }}</span
                >
              </v-btn>
            </v-col>

            <v-col v-if="hasPermission(128)" cols="12" sm="" class="pt-0 pb-2">
              <v-btn
                color="warning"
                @click="refund"
                :disabled="loading"
                small
                block
              >
                <v-icon small left>mdi-cash-refund</v-icon> Reembolsar
              </v-btn>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close" small text block> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import TICKET from "@/services/admin/ticket";
import GuestTicketTransfer from "./GuestTicketTransfer.vue";

export default {
  components: { GuestTicketTransfer },
  data: () => ({
    dialog: false,
    loading: false,
    ticketResent: false,
    payment: null,
    expanded: [],
    PAYMENT,
    headers: [
      { text: "ID", value: "id", sortable: false },
      {
        text: "Setor",
        value: "TicketBlock.TicketGroup.name",
      },
      { text: "Lote", value: "TicketBlock.name" },
      { text: "", value: "action", align: "end", sortable: false },
      { text: "", value: "data-table-expand", align: "end", sortable: false },
    ],
  }),
  methods: {
    expandTicket(ticket) {
      this.expanded = this.expanded[0] === ticket.id ? [] : [ticket.id];
    },
    transfer(ticket) {
      this.$root.$emit("transfer-ticket", { ...ticket, Payment: this.payment });
    },
    refund() {
      const ticket = this.payment.tickets[0];
      this.$root.$emit("refund-ticket", { ...ticket, Payment: this.payment });
    },
    async resend(ticket) {
      if (!this.hasPermission(64)) return;
      try {
        this.loading = true;
        const response = await TICKET.resend(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          ticket.id
        );
        this.ticketResent = true;
        console.log("Payment refresh", response);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async refreshTicketPayment() {
      if (!this.hasPermission(0)) return;
      try {
        this.loading = true;
        const response = await TICKET.updateTicketPayment(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          this.payment.tickets[0].id
        );
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.expanded = [];
      this.ticketResent = false;
      this.$emit("refresh");
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("payment-details", (payment) => {
      this.dialog = true;
      this.payment = payment;
    });
  },
};
</script>

<style></style>
