<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6 class="mb-0">Mesas</h6>
      <h5 class="mb-0">1 ingresso</h5>
    </v-card-title>
    <v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  computed: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
