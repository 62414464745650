<template>
  <v-card outlined :loading="loading" class="rounded-lg">
    <v-card-title>
      <h6>Linha do tempo de usuários</h6>
    </v-card-title>
    <v-card-text>
      <apexCharts
        type="area"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  computed: {
    chartSeries() {
      const reportDates = this.data.timeline.rows;

      return [
        {
          name: "Usuários",
          data: reportDates
            .map(({ dimensions, metrics }) => [
              moment(dimensions[0], "YYYYMMDDHH").toISOString(),
              parseInt(metrics[0]), // activeUsers
            ])
            .sort(([a], [b]) => moment(a).diff(moment(b))),
        },
        // {
        //   name: "Novos usuários",
        //   data: reportDates
        //     .map(({ dimensions, metrics }) => [
        //       moment(dimensions[0], "YYYYMMDDHH").toISOString(),
        //       parseInt(metrics[1]), // newUsers
        //     ])
        //     .sort(([a], [b]) => moment(a).diff(moment(b))),
        // },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "area",
          height: 350,
          stacked: false,
          toolbar: { show: true, tools: { download: false, reset: false } },
        },
        dataLabels: { enabled: false },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: (_, timestamp, __) =>
              moment(timestamp).format("DD/MMM HH:00"),
          },
        },
        yaxis: { labels: {} },
      };
    },
  },
  props: {
    data: {
      type: Object || null,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
