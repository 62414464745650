<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="text-h6"> Enviar fotos </v-card-title>
      <v-card-text class="pt-1 pb-0">
        <file-drop multiple accept="image/*" @change="addFiles" class="mb-4" />
        <v-row dense class="mx-0">
          <v-col
            cols="6"
            sm="4"
            v-for="(file, index) in files"
            :key="index"
            class=""
          >
            <div style="position: relative">
              <v-img class="rounded" :aspect-ratio="1" :src="file.preview" />

              <template v-if="!loading && !finished">
                <v-btn icon @click="removeFile(index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <template v-else>
                <div
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                  "
                  class="d-flex justify-center align-center"
                >
                  <v-progress-circular
                    v-if="!results[index].error && !results[index].success"
                    :indeterminate="results[index].loading"
                    size="50"
                  />
                  <v-avatar v-else>
                    <v-icon
                      v-if="results[index].success"
                      color="success"
                      x-large
                    >
                      mdi-check
                    </v-icon>
                    <v-icon v-else color="error" x-large>mdi-close</v-icon>
                  </v-avatar>
                </div>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="pa-0">
        <div class="w-full">
          <div>
            <v-progress-linear
              v-if="loading"
              color="primary"
              :value="(currIndex / files.length)*100"
              height="3"
            />
          </div>
          <div class="d-flex px-4 py-2">
            <v-spacer></v-spacer>
            <v-btn class="ml-1" text :disabled="loading" @click="close">
              Fechar
            </v-btn>
            <v-btn
              v-if="!finished"
              class="ml-1"
              color="primary"
              :disabled="loading || !files.length"
              @click="process"
            >
              Enviar
            </v-btn>
            <v-btn v-else class="ml-1" text :disabled="loading" @click="clear">
              Limpar
            </v-btn>
            <v-spacer></v-spacer>
          </div>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FileDrop from "../../../global/FileDrop.vue";
import GALLERY from "@/services/admin/party/gallery";

export default {
  components: { FileDrop },
  data: () => ({
    dialog: false,
    loading: false,
    finished: false,
    valid: false,
    error: false,
    party: {},
    files: [],
    results: [],
    currIndex: 0,
  }),

  methods: {
    clear() {
      this.files = [];
      this.results = [];
      this.finished = false;
      this.error = false;
    },
    async process() {
      this.currIndex = 0;
      this.results = Array.from(new Array(this.files.length), (x) => ({
        error: false,
        success: false,
        loading: false,
      }));
      this.loading = true;
      // For using value and index
      for (const [index, file] of this.files.entries()) {
        try {
          this.currIndex = index;
          this.results[index].loading = true;
          const response = await this.uploadImage(file);
          this.results[index].success = true;
        } catch (error) {
          this.results[index].error = error.message || "Erro ao enviar foto";
        } finally {
          this.results[index].loading = false;
        }
      }

      this.$emit("success");
      this.loading = false;
      this.finished = true;
    },
    async uploadImage(file) {
      return GALLERY.upload(this.party.organizationId, this.party.id, file);
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    open({ party }) {
      this.party = Object.assign({}, party);
      this.files = [];
      this.results = [];
      this.finished = false;
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
    addFiles(files) {
      this.files.push(
        ...files.map((f) => {
          f.preview = URL.createObjectURL(f);
          f.description = "";
          f.error = false;
          return f;
        })
      );
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("upload-photo", this.open);
  },
};
</script>

<style></style>
