<template>
  <v-dialog v-model="dialog" width="600" persistent scrollable>
    <v-card>
      <v-card-title class="text-h6">
        {{ transaction.id ? "Editar transação" : "Adicionar transação" }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12">
              <v-btn-toggle
                v-model="type"
                mandatory
                width="100%"
                class="d-flex"
              >
                <v-btn text color="success" style="width: 50%">
                  Entrada
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
                <v-btn text color="warning" style="width: 50%">
                  Saída
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col cols="12">
              <v-select
                outlined
                :label="`Pertence a${!!transaction.partyId ? 'o evento' : ''}:`"
                v-model="transaction.partyId"
                :items="parties"
                hide-details
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                v-model="transaction.amount"
                :rules="[
                  (v) => !!v || 'Valor é obrigatório',
                  (v) => v > 0 || 'Valor deve ser maior que 0',
                ]"
                prefix="R$"
                :label="labels.amount"
                type="number"
                step="0.01"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="transaction.date"
                :label="labels.date"
                type="datetime-local"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                outlined
                v-model="transaction.payedFrom"
                :label="labels.payedFrom"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                outlined
                v-model="transaction.payedTo"
                :label="labels.payedTo"
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!-- <label class="d-block">Status da transação</label>
              <v-btn
                v-if="transaction.status == 'CANCELED'"
                disabled
                block
                color="error"
              >
                Transação cancelada
              </v-btn>
              <v-btn-toggle v-else v-model="transaction.status" mandatory>
                <v-btn block text color="warning"> Pendente </v-btn>
                <v-btn block text color="success"> Realizada </v-btn>
              </v-btn-toggle> -->
              <v-select
                outlined
                label="Status da transação"
                v-model="transaction.status"
                :items="statusList"
                hide-details
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                outlined
                :label="labels.paymentType"
                v-model="transaction.paymentType"
                :items="paymentTypeList"
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                v-model="transaction.description"
                label="Descrição"
                rows="2"
                auto-grow
                :rules="[(v) => !!v || 'Descrição é obrigatória']"
              ></v-textarea>

              <!-- <base-md-editor
                v-model="transaction.description"
              /> -->
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="secondary"
          text
          :disabled="loading"
          @click="close"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          :disabled="!valid"
          @click="save"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import FINANCIAL from "@/services/admin/financial";
import moment from "moment";

const defaultTransaction = () => ({
  amount: 0,
  status: "DONE",
  partyId: null,
  date: null,
  paymentType: "UNKNOWN",
});

export default {
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    transaction: defaultTransaction(),
    p: [],
    type: 1,
    typesName: ["IN", "OUT"],
    paymentTypeList: [
      { text: "Desconhecido", value: "UNKNOWN" },
      { text: "Pix", value: "PIX" },
      { text: "Dinheiro", value: "MONEY" },
      { text: "Cartão", value: "CREDIT_CARD" },
      { text: "Transferência Bancária", value: "BANK_TRANSFER" },
    ],
    statusList: [
      { text: "Pendente", value: "PENDING" },
      { text: "Efetuada", value: "DONE" },
    ],
  }),

  methods: {
    open(data = {}) {
      this.transaction = Object.assign(defaultTransaction(), data);
      delete this.transaction.Party;
      delete this.transaction.User;

      if (this.transaction.amount < 0) {
        this.type = 1;
        this.transaction.amount *= -1;
      } else this.type = 0;
      if (this.transaction.date)
        this.transaction.date = moment(this.transaction.date).format(
          "YYYY-MM-DDTHH:mm"
        );

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.loading = false;
        this.error = false;
        this.type = 1;
        this.transaction = defaultTransaction();
      });
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        let orgId = this.selectedOrganization.id;
        let obj = {
          ...this.transaction,
          amount: parseFloat(
            this.type == 0 ? this.transaction.amount : -this.transaction.amount
          ),
        };
        let response;
        if (this.transaction.id) {
          response = await FINANCIAL.transaction.update(orgId, obj);
        } else {
          response = await FINANCIAL.transaction.create(orgId, obj);
        }
        this.$emit("success", response.transaction);
        this.close();
      } catch (e) {
        this.error = e.message;
        this.loading = false;
      }
    },
    async getParties() {
      try {
        let { parties } = await ORGANIZATION.party.getAll(
          this.selectedOrganization.id
        );
        this.p = parties;
      } catch (e) {
        this.error = e.message;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    parties() {
      let response = [{ value: null, text: "Organização" }];
      this.p.forEach((p) => response.push({ value: p.id, text: p.name }));
      return response;
    },
    labels() {
      let type = this.typesName[this.type] || "IN";

      return {
        amount: type == "IN" ? "Valor de entrada" : "Valor de saída",
        date: type == "IN" ? "Data da entrada" : "Data da saída",
        paymentType:
          type == "IN" ? "Forma de recebimento" : "Forma de pagamento",
        payedTo: type == "IN" ? "Recebido de" : "Pago para",
        payedFrom: type == "IN" ? "Recebido por" : "Pago de",
      };
    },
  },
  mounted() {
    this.$root.$on("transaction-modal", this.open);
    this.$parent.$on("transaction-modal", this.open);
    this.getParties();
  },
};
</script>

<style></style>
