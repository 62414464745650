<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
  >
    <v-card>
      <v-card-text class="pt-1 pb-0" v-if="gallery">
        <v-img :src="gallery.url" class="rounded" contain height="300" />
        <v-textarea
          v-model="gallery.description"
          label="Descrição"
          outlined
          class="mt-2"
        />

        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-btn class="ml-1" color="primary" :loading="loading" @click="save">
          Enviar
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FileDrop from "../../../global/FileDrop.vue";
import GALLERY from "@/services/admin/party/gallery";

export default {
  components: { FileDrop },
  data: () => ({
    dialog: false,
    error: false,
    loading: false,
    gallery: null,
    party: null,
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        await GALLERY.update(
          this.party.organizationId,
          this.party.id,
          this.gallery.id,
          this.gallery
        );
        
        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    open({ gallery, party }) {
      this.party = Object.assign({}, party);
      this.gallery = Object.assign({}, gallery);
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
    addFiles(files) {
      this.files.push(
        ...files.map((f) => {
          f.preview = URL.createObjectURL(f);
          f.description = "";
          f.error = false;
          return f;
        })
      );
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("edit-photo", this.open);
  },
};
</script>

<style></style>
