<template>
  <v-autocomplete
    v-model="location"
    :items="searchResults"
    :loading="isLoading"
    :search-input.sync="search"
    item-text="name"
    item-value="data"
    :label="label"
    :outlined="outlined"
    hide-no-data
    hide-selected
    :placeholder="placeholder"
    :prepend-icon="prependIcon"
    hide-details
    :rules="rules"
    return-object
    :filter="filter"
    :rounded="rounded"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-html="item.name"></v-list-item-title>
        <v-list-item-subtitle
          v-if="!hiddenItemsDetails"
          v-html="item.description"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>

    <template v-slot:selection="{ item }">
      {{ item.name }}
      <small v-if="!hiddenItemDetails" class="text--secondary ml-2">{{
        item.description
      }}</small>
    </template>
  </v-autocomplete>
</template>

<script>
const { VUE_APP_MAPS_KEY } = process.env;
import { Loader } from "@googlemaps/js-api-loader";

export default {
  data: () => ({
    location: null,
    isLoading: false,
    searchResults: [],
    search: "",
    autocomplete: null, // will reveal this later 🕵️
    timeout: null,

    // Google Maps API
    autocomplete: null,
    places: null,
  }),
  props: {
    hiddenItemDetails: {
      type: Boolean,
      default: false,
    },
    hiddenItemsDetails: {
      type: Boolean,
      default: false,
    },
    searchTypes: {
      type: Array,
      default: () => [],
    },
    detailsFields: {
      type: Array,
      default: () => [],
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Busque por um local ou endereço",
    },
    minLength: {
      type: Number,
      default: 3,
    },
    delay: {
      type: Number,
      default: 500,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async init() {
      const loader = new Loader({
        apiKey: VUE_APP_MAPS_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      const google = await loader.load();
      this.autocomplete = new google.maps.places.AutocompleteService();
      this.places = new google.maps.places.PlacesService(
        new google.maps.Map(document.createElement("div"), {})
      );
    },
    formatAddress(data) {
      return {
        name: data.name,
        street: this.findAddressComponent(data.address_components, "route")
          ?.long_name,
        number: this.findAddressComponent(
          data.address_components,
          "street_number"
        )?.long_name,
        complement: this.findAddressComponent(
          data.address_components,
          "subpremise"
        )?.long_name,
        neighborhood: this.findAddressComponent(
          data.address_components,
          "sublocality"
        )?.long_name,
        city: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_2"
        )?.long_name,
        state: this.findAddressComponent(
          data.address_components,
          "administrative_area_level_1"
        )?.short_name,
        country: this.findAddressComponent(data.address_components, "country")
          ?.long_name,
        zipCode: this.findAddressComponent(
          data.address_components,
          "postal_code"
        )?.long_name,
        lat: data.geometry.location.lat(),
        lng: data.geometry.location.lng()
      };
    },
    findAddressComponent(data, componentName) {
      return data.find((item) => item.types.includes(componentName));
    },
    runSearch(val) {
      this.autocomplete.getPlacePredictions(
        {
          input: val,
          types: this.searchTypes,
          language: "pt-BR",

          componentRestrictions: {
            country: "br",
          },
        },
        this.displaySuggestions
      );
    },
    filter(item, queryText, itemText) {
      return true;
    },
    addressDetails(place) {
      this.$emit("input:raw", place);
      this.$emit("input", this.formatAddress(place));
    },
    displaySuggestions(predictions, status) {
      this.isLoading = false;
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        return (this.searchResults = []);
      }

      this.searchResults = predictions.map((prediction) => {
        return {
          name: prediction.structured_formatting.main_text,
          description: prediction.structured_formatting.secondary_text,
          placeId: prediction.place_id,
        };
      });
    },
  },
  mounted() {
    this.init();
    if (this.value) {
      this.location = this.value;
      this.searchResults = [this.value];
    }
  },
  watch: {
    location(newValue) {
      if (!newValue) return this.$emit("input", null);
      if (!newValue.placeId) return;
      this.places.getDetails(
        {
          language: "pt-BR",
          placeId: newValue.placeId,
          fields: ["address_component", "name", "geometry", ...this.detailsFields],
        },
        this.addressDetails
      );
    },
    search(val) {
      if (!val || val.trim().length < this.minLength) return;
      if (this.timeout) clearTimeout(this.timeout);
      this.isLoading = true;
      this.timeout = setTimeout(() => this.runSearch(val), this.delay);
    },
  },
};
</script>

<style></style>
