<template>
  <div>
    <v-card class="mb-4 mt-3">
      <v-alert v-if="error" type="error">
        {{ error }}
      </v-alert>
      <v-row align="center" no-gutters class="px-3">
        <v-col cols="auto">
          <v-img
            class="rounded mr-4"
            :src="party.cover"
            :aspect-ratio="16 / 9"
            width="300"
          />
        </v-col>
        <v-col cols="" class="pa-4">
          <h5>
            {{ party.name }}
            <v-chip color="secondary" small>
              <span v-if="ratingCount">{{ ratingCount }} avaliações</span>
              <span v-else>Nenhuma avaliação</span>
            </v-chip>
          </h5>
          <v-rating
            :value="ratingAvg"
            readonly
            large
            dense
            background-color="primary"
          />
        </v-col>
      </v-row>
    </v-card>

    <!-- Comments -->
    <h6 v-if="ratingsComments.length">
      Comentário{{ ratingsComments.length == 1 ? "" : "s" }}
    </h6>
    <v-card
      v-for="rating in ratingsComments"
      :key="rating.id"
      class="mt-2"
      outlined
    >
      <v-card-text>
        <v-rating
          class="float-right"
          :value="rating.value"
          readonly
          small
          dense
          background-color="primary"
        />
        <p class="mb-0">{{ rating.comment }}</p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";

export default {
  data: () => ({
    loading: false,
    error: false,
    ratings: [],
  }),
  methods: {
    async getRating() {
      try {
        this.loading = true;
        const { ratings } = await ORGANIZATION.party.rating.getAll(
          this.party.organizationId,
          this.party.id
        );
        this.ratings = ratings;
      } catch (error) {
        this.error = error.message || "Erro ao carregar avaliações";
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    ratingAvg() {
      return (
        this.ratings.reduce((acc, rating) => acc + rating.value, 0) /
          this.ratings.length || 0
      );
    },
    ratingsComments() {
      return this.ratings.filter((rating) => rating.comment);
    },
    ratingCount() {
      return this.ratings.length;
    },
  },

  mounted() {
    this.getRating();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
