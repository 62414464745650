<template>
  <div outlined class="pa-2 my-2">
    <div class="d-flex gap-2 align-center mb-3">
      <base-avatar
        :seed="item.Ticket.Owner.id"
        :src="item.Ticket.Owner.photo"
        :size="50"
      />
      <div>
        <h6 class="mb-0">{{ item.Ticket.Owner.name }}</h6>
        <a @click="openWhatsapp">
          {{ item.Ticket.Owner.phone }}
        </a>
      </div>
      <v-spacer />
      <v-btn @click="openWhatsapp" fab color="success" x-small>
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
    </div>
    <v-card outlined class="pa-4 mb-2">
      <div
        class="d-flex align-end mb-1 justify-space-between flex-wrap-reverse"
      >
        <h6 class="font-weight-bold mb-0">Motivo</h6>
        <div class="text-end">
          <p class="mb-0 lh-1 font-weight-medium">
            Solicitado em: {{ item.createdAt | date("DD/MM/YY HH:mm") }}
          </p>
          <small class="lh-1">
            {{
              [item.Ticket.Payment.payedAt, item.createdAt] | dateDiff
            }}
            após o pagamento
          </small>
        </div>
      </div>
      {{ item.reason }}
    </v-card>
    <v-card outlined class="pa-4 mb-2">
      <h6 class="font-weight-bold mb-1">Compra</h6>
      <div class="d-flex flex-wrap gap-x-5 gap-y-1 justify-space-around">
        <span>
          <b class="font-weight-medium">Data do pagamento:</b>
          {{ item.Ticket.Payment.payedAt | date("DD/MM/YY HH:mm") }}
        </span>
        <span>
          <b class="font-weight-medium">Vendido por: </b>
          <template v-if="!item.Ticket.Seller">Online</template>
          <template v-else>
            <base-avatar
              :seed="item.Ticket.Seller.id"
              :src="item.Ticket.Seller.photo"
              :size="20"
            />
            {{ item.Ticket.Seller.name }}
          </template>
        </span>
        <span>
          <b class="font-weight-medium">Meio de Pagamento: </b>
          {{ PAYMENT.paymentMethod[item.Ticket.Payment.paymentMethod].text }}
        </span>
        <span>
          <b class="font-weight-medium">Status do Pagamento: </b>
          <v-chip
            x-small
            label
            :color="PAYMENT.ticketStatus[item.Ticket.Payment.status].color"
          >
            {{ PAYMENT.ticketStatus[item.Ticket.Payment.status].text }}
          </v-chip>
        </span>
      </div>
    </v-card>
    <v-card outlined class="pa-4 mb-2" v-if="item.ClosedBy">
      <h6 class="font-weight-bold mb-1">Solicitação encerrada por</h6>
      <div class="d-flex gap-2">
        <base-avatar
          :seed="item.ClosedBy.id"
          :src="item.ClosedBy.photo"
          :size="50"
        />
        <div>
          <h6 class="mb-0 text-14">{{ item.ClosedBy.name }}</h6>
          <span> em {{ item.closedAt | date("DD/MM/YYYY HH:mm") }} </span>
        </div>
      </div>
    </v-card>

    <v-alert type="error" v-if="error">
      {{ error }}
    </v-alert>

    <div class="d-flex flex-wrap gap-2">
      <v-btn
        v-if="item.Ticket.Payment.status != 'refunded'"
        @click="refundTicket"
        class="flex-grow-1"
        color="primary"
        :disabled="loading"
        :outlined="confirmingRefund"
      >
        Reembolsar
      </v-btn>
      <v-btn
        v-if="!item.closedAt"
        @click="confirmRefund"
        class="flex-grow-1"
        color="error"
        :loading="loading"
      >
        <template v-if="confirmingRefund">
          <v-icon small left>mdi-alert</v-icon> Confirmar recusa
        </template>
        <template v-else> Recusar </template>
      </v-btn>
    </div>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import PARTY from "@/services/admin/party";
import moment from "moment";

export default {
  data: () => ({
    PAYMENT,
    confirmingRefund: false,
    loading: false,
    error: null,
  }),
  methods: {
    confirmRefund() {
      if (!this.confirmingRefund) {
        this.confirmingRefund = true;
        setTimeout(() => {
          this.confirmingRefund = false;
        }, 3000);
      } else {
        this.closeRefoundRequest();
      }
    },
    async closeRefoundRequest() {
      try {
        this.loading = true;
        this.error = null;
        await PARTY.refund.closeRefoundRequest(
          this.item.Ticket.TicketBlock.TicketGroup.Party.organizationId,
          this.item.Ticket.TicketBlock.TicketGroup.Party.id,
          this.item.id
        );
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao recusar reembolso";
      } finally {
        this.loading = false;
      }
    },
    refundTicket() {
      this.$root.$emit("refund-ticket", this.item.Ticket);
    },
    openWhatsapp() {
      const phone =
        `${this.item.Ticket.Owner.ddi}${this.item.Ticket.Owner.phone}`.replace(
          /\D/g,
          ""
        );

      window.open(
        `https://wa.me/${phone}?text=Olá ${
          this.item.Ticket.Owner.name.split(" ")[0]
        }%0A`
      );
    },
    
  },
  computed: {},
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
