<template>
  <div class="mt-2">
    <v-expansion-panels focusable v-model="expansePanel" class="">
      <v-expansion-panel v-for="(ep, i) in expansionsPanels" :key="ep.name">
        <v-expansion-panel-header class="justify-start" disable-icon-rotate>
          <div class="d-flex align-center" style="gap: 1rem">
            <v-icon>{{ ep.icon }}</v-icon>
            <h6 class="mb-0">{{ ep.title }}</h6>
          </div>
          <template v-slot:actions>
            <v-btn small icon v-if="!!ep.saving" :loading="ep.saving.loading">
              <v-icon :color="ep.saving.error ? 'error' : 'success'">
                {{
                  ep.saving.error
                    ? "mdi-alert-circle"
                    : "mdi-content-save-check"
                }}
              </v-icon>
            </v-btn>
            <v-btn icon v-else-if="expansePanel !== i || expansePanel == null">
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn icon color="primary" v-else>
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="ep.component"
            :party="party"
            :ref="ep.name"
          ></component>
          <div class="d-flex justify-end">
            <v-btn
              color="success"
              :loading="ep.saving.loading"
              @click="saveGroup(i)"
            >
              Salvar
            </v-btn>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Basic from "./detailsComponents/Basic.vue";
import Multimedia from "./detailsComponents/Multimedia.vue";
import Location from "./detailsComponents/Location.vue";
import Privacy from "./detailsComponents/Privacy.vue";
import PartySlug from "./detailsComponents/PartySlug.vue";

export default {
  components: { Basic, Location, Privacy, PartySlug, Multimedia },
  data: () => ({
    loading: false,
    fileLoading: false,
    valid: false,
    error: false,
    expansePanel: 0,
    expansionsPanels: [
      {
        name: "basic",
        icon: "mdi-information",
        title: "Detalhes",
        component: "basic",
        saving: false,
      },
      {
        name: "location",
        icon: "mdi-map-marker",
        title: "Local",
        component: "location",
        saving: false,
      },
      {
        name: "multimedia",
        icon: "mdi-video",
        title: "Vídeo e Música",
        component: "Multimedia",
        saving: false,
      },
      {
        name: "privacy",
        icon: "mdi-lock",
        title: "Privacidade",
        component: "Privacy",
        saving: false,
      },
      {
        name: "slug",
        icon: "mdi-link",
        title: "Link",
        component: "PartySlug",
        saving: false,
      },
    ],
  }),
  methods: {
    async saveGroup(index) {
      try {
        this.expansionsPanels[index].saving = {
          loading: true,
          error: false,
        };

        await this.$refs[this.expansionsPanels[index].name][0].save();

        this.expansionsPanels[index].saving = {
          loading: false,
          error: false,
        };
        this.$emit("refresh");
      } catch (e) {
        console.log(e);
        this.expansionsPanels[index].saving = {
          loading: false,
          error: true,
        };
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.expansionsPanels[index].saving = false;
      }
    },
  },
  watch: {
    expansePanel(val, oldVal) {
      if (oldVal != undefined) this.saveGroup(oldVal);
      if (val != undefined) this.expansionsPanels[val].saving = false;
    },
  },
  mounted() {
    this.policies = Object.assign({}, this.party);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
