<template>
  <div class="mt-4">
    <v-text-field
      v-model="slug"
      label="Link"
      outlined
      :placeholder="party.id"
      dense
      :prefix="`${host}/shop/${this.selectedOrganization.slug}/`"
      :rules="rules"
      :append-outer-icon="successCopy ? 'mdi-check' : 'mdi-content-copy'"
      @click:append-outer="copyLink"
    >
    </v-text-field>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";
import { mapGetters } from "vuex";
const uuidRegex = /^[a-f\d]{8}(-[a-f\d]{4}){4}[a-f\d]{8}$/i;
import slugify from "@/utils/slugfy";

export default {
  data() {
    return {
      host: window.location.host,
      slug: this.party?.slug,
      rules: [
        (v) => !v || v.length > 6 || "O link deve ter mais de 6 caracteres",
        (v) => !v || v.length < 20 || "O link deve ter menos de 40 caracteres",
        // verify is nos is a uuid
        (v) => !v || !uuidRegex.test(v) || "O link deve ser um UUID válido",
      ],
      successCopy: false,
    };
  },
  methods: {
    copyLink() {
      const link = `${this.host}/shop/${this.selectedOrganization.slug}/${
        this.slug || this.party.id
      }`;
      navigator.clipboard.writeText(link);
      this.successCopy = true;
      setTimeout(() => {
        this.successCopy = false;
      }, 2000);
    },
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        slug: this.slug ? this.slug.trim() : null,
      });
    },
  },

  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },

  watch: {
    party: {
      handler: function (val) {
        this.slug = val.slug;
      },
      deep: true,
    },
    slug: {
      handler: function (val) {
        if (!val) return;
        this.slug = slugify(val);
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
