<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Vendedores</h6>
      <v-btn-toggle v-model="filterType" color="primary" small mandatory>
        <v-btn small value="all"> Todos </v-btn>
        <v-btn small value="user"> Usuários </v-btn>
        <v-btn small value="pos"> PDVs </v-btn>
      </v-btn-toggle>
    </div>
    <v-alert v-if="error" type="error" class="mt-2">
      {{ error }}
    </v-alert>

    <div v-else>
      <v-card
        v-if="selectedSellers.length"
        rounded="xl"
        class="pa-2 px-4 mt-2 d-flex justify-space-between align-center"
        outlined
      >
        <span class="mb-0">
          <b>
            {{ selectedSellers.length }} vendedor{{
              selectedSellers.length > 1 ? "es" : ""
            }}
            selecionado{{ selectedSellers.length > 1 ? "s" : "" }}
          </b>
        </span>
        <v-btn @click="massiveEdit" small elevation="0" class="ml-2">
          <v-icon left small> mdi-pencil </v-icon>
          Editar
        </v-btn>
      </v-card>

      <v-data-table
        v-model="selectedSellers"
        show-select
        :headers="headers"
        :items="list"
        :loading="loading"
      >
        <template v-slot:header> </template>
        <template v-slot:item.name="{ item }">
          <div class="d-flex gap-2 align-center">
            <baseAvatar
              v-if="!item.photo && item.name"
              :size="35"
              :seed="item.id"
            />
            <v-avatar v-else size="35">
              <img v-if="item.name" :src="item.photo" />
              <v-icon v-else-if="item.type == 'user'" large>
                mdi-account-question
              </v-icon>
              <v-icon v-else large>mdi-cash-register</v-icon>
            </v-avatar>
            <div class="d-flex flex-column align-start">
              <p
                class="mb-0 lh-1 font-weight-medium"
                :class="{ textItalic: !item.name }"
              >
                {{ item.name || item.Address?.name || "Conta não criada" }}
              </p>
              <small>{{
                item.phone ||
                (item.Address &&
                  `${item.Address.city} - ${item.Address.state}`) ||
                ""
              }}</small>
            </div>
          </div>
        </template>
        <template v-slot:item.ticketBlocksLength="{ item }">
          <v-tooltip top v-if="item.permissions?.owner">
            <template v-slot:activator="{ on, attrs }">
              <v-chip label small v-bind="attrs" v-on="on">
                <v-icon left x-small>mdi-crown</v-icon>
                Todos
              </v-chip>
            </template>
            Acesso a todos os lotes
          </v-tooltip>
          <v-tooltip top v-else-if="!item.TicketBlockSeller.length">
            <template v-slot:activator="{ on, attrs }">
              <v-chip label small v-bind="attrs" v-on="on">
                <v-icon left x-small>mdi-lock</v-icon>
                Nenhum
              </v-chip>
            </template>
            Nenhum lote para venda
          </v-tooltip>

          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-chip label small class="" v-bind="attrs" v-on="on">
                {{ item.TicketBlockSeller.length }} lote{{
                  item.TicketBlockSeller.length > 1 ? "s" : ""
                }}
              </v-chip>
            </template>
            <div class="d-flex flex-column">
              <p
                v-for="tb in item.TicketBlockSeller"
                :key="tb.id"
                class="mb-0 d-flex align-center gap-2"
              >
                <b class="font-weight-bold">{{ tb.TicketGroup.name }}</b> •
                {{ tb.name }}
              </p>
            </div>
          </v-tooltip>
        </template>
        <template v-slot:item.permissions="{ item }">
          <div class="d-flex flex-column align-start flex-wrap gap-1">
            <v-chip label x-small v-if="item.permissions?.owner">
              <v-icon left x-small>mdi-crown</v-icon>
              Administrador
            </v-chip>
            <v-chip x-small v-else-if="!item.TicketBlockSeller.length">
              <v-icon left x-small>mdi-lock</v-icon>
              Nenhum lote
            </v-chip>
            <template v-else-if="item.type == 'user'">
              <v-chip x-small v-if="item.permissions.online" color="success">
                <v-icon left x-small> mdi-web </v-icon>
                Venda Online
              </v-chip>
              <v-chip x-small v-if="item.permissions.offline" color="info">
                <v-icon left x-small> mdi-account-cash </v-icon>
                Venda Em Dinheiro
              </v-chip>
            </template>
          </div>
        </template>
        <template v-slot:item.SellerProfile="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-chip v-if="item.SellerProfile" label small color="success">
                  <v-icon left x-small>mdi-account-box</v-icon>
                  Exibir
                </v-chip>
                <v-chip v-else label small color="info">
                  <v-icon left x-small>mdi-account-lock</v-icon>
                  Não exibir
                </v-chip>
              </div>
            </template>

            <b>{{ item.SellerProfile ? "Exibir" : "Não exibir" }}</b> perfil do
            vendedor na página do evento
          </v-tooltip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn @click="editSellerProfile(item)" small icon>
            <v-icon small> mdi-account-box </v-icon>
          </v-btn>
          <v-btn @click="editSeller(item)" small icon>
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>

    <SellerModal
      :ticketGroups="ticketGroups"
      :party="party"
      @success="getSellers"
    />
    <MassiveSellerModal
      :ticketGroups="ticketGroups"
      :party="party"
      @success="getSellers"
    />
    <SellerProfileModal :party="party" @success="getSellers" />
  </div>
</template>

<script>
import TICKET from "@/services/admin/ticket";
import SELLER from "../../../services/admin/party/seller";
import { mapGetters } from "vuex";
import SellerModal from "./modal/SellerModal.vue";
import SellerProfileModal from "./modal/SellerProfileModal.vue";
import MassiveSellerModal from "./modal/MassiveSellerModal.vue";
export default {
  data: () => ({
    loading: false,
    error: null,
    sellers: [],
    posSellers: [],
    ticketBlocks: [],
    ticketGroups: [],
    selectedSellers: [],
    filterType: "all",
    headers: [
      {
        text: "Vendedor",
        value: "name",
      },
      {
        text: "Lotes",
        value: "ticketBlocksLength",
        align: "center",
      },
      {
        text: "Permissões",
        value: "permissions",
        sortable: false,
      },
      {
        text: "Perfil",
        value: "SellerProfile",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    addSeller() {
      this.$emit("seller-modal");
    },
    editSeller(seller) {
      this.$emit("seller-modal", seller);
    },
    editSellerProfile(seller) {
      this.$root.$emit("seller-profile-modal", seller);
    },
    massiveEdit() {
      if (this.selectedSellers.length === 1)
        return this.editSeller(this.selectedSellers[0]);

      this.$emit("massive-seller-modal", this.selectedSellers);
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (e) {
        this.ticketGroups = false;
      }
    },

    async getSellers() {
      try {
        this.loading = true;
        const { sellers, posSellers, ticketBlocks } = await SELLER.getAll(
          this.selectedOrganization.id,
          this.party.id
        );

        this.sellers = sellers.map((s) => ({
          ...s,
          type: "user",
          ticketBlocksLength: s.TicketBlockSeller.length,
        }));

        this.posSellers = posSellers.map((pos) => ({
          ...pos,
          type: "pos",
          ticketBlocksLength: pos.TicketBlockSeller.length,
        }));

        this.ticketBlocks = ticketBlocks;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    list() {
      if (this.filterType === "user") return this.sellers;
      if (this.filterType === "pos") return this.posSellers;
      return [...this.sellers, ...this.posSellers];
    },
  },
  async mounted() {
    await this.getSellers();
    this.getTicketGroups();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  components: { SellerModal, SellerProfileModal, MassiveSellerModal },
};
</script>

<style></style>
