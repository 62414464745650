<template>
  <div style="position: relative" ref="galleryItens">
    <div
      class="d-flex justify-end mb-2"
      v-if="!fixCols"
      v-intersect="onIntersect"
    >
      <v-btn
        :disabled="zoomLevel === 0"
        @click="zoomOut"
        class="rounded-r-0 rounded-l-xl"
        elevation="0"
        small
        color="primary"
      >
        <v-icon small>mdi-magnify-minus-outline</v-icon>
      </v-btn>
      <v-btn
        :disabled="zoomLevel === zoomLevels.length - 1"
        @click="zoomIn"
        class="rounded-l-0 rounded-r-xl"
        elevation="0"
        small
        color="primary"
      >
        <v-icon small>mdi-magnify-plus-outline</v-icon>
      </v-btn>
    </div>
    <RecycleScroller
      class="scroller"
      :items="mappedGallery"
      :item-size="componentWidth / itensPerRow || 300"
      :key-field="'id'"
      :gridItems="itensPerRow"
    >
      <template #default="{ item, index, active }">
        <v-card v-if="active" class="ma-1">
          <v-img
            :src="item.preview"
            class="rounded"
            :aspect-ratio="1"
            :lazy-src="item.lazySrc"
            style="cursor: pointer"
            @click="imgClick(index)"
            :id="`gallery-${index}`"
          >
          </v-img>
          <slot :item="item" :index="index"></slot>
        </v-card>
      </template>
    </RecycleScroller>

    <v-scroll-y-transition mode="out-in">
      <v-card
        class="pa-1 rounded-l-xl rounded-r-xl"
        style="position: fixed; bottom: 20px; right: 20px"
        v-if="!fixCols && !zoomIsVisible"
      >
        <v-btn
          :disabled="zoomLevel === 0"
          @click="zoomOut"
          class="rounded-r-0 rounded-l-xl"
          elevation="0"
          color="primary"
        >
          <v-icon>mdi-magnify-minus-outline</v-icon>
        </v-btn>
        <v-btn
          :disabled="zoomLevel === zoomLevels.length - 1"
          @click="zoomIn"
          class="rounded-l-0 rounded-r-xl"
          elevation="0"
          color="primary"
        >
          <v-icon>mdi-magnify-plus-outline</v-icon>
        </v-btn>
      </v-card>
    </v-scroll-y-transition>

    <v-scroll-y-transition mode="out-in">
      <v-btn
        v-if="!fixCols && !zoomIsVisible"
        elevation="0"
        fixed
        bottom
        left
        fab
        color="primary"
        @click="$vuetify.goTo(0, { duration: 500, easing: 'easeInOutCubic' })"
      >
        <v-icon large>mdi-arrow-up-thin</v-icon>
      </v-btn>
    </v-scroll-y-transition>

    <v-dialog
      content-class="dialog-no-shadow"
      v-model="imageView.open"
      fullscreen
    >
      <div
        style="background-color: rgba(0, 0, 0, 0.95); height: 100%"
        class="pa-4"
      >
        <v-carousel
          v-model="imageView.index"
          height="100%"
          :show-arrows-on-hover="!loading"
          :show-arrows="!loading"
          :touchless="loading"
          hide-delimiter-background
          hide-delimiters
        >
          <v-carousel-item
            v-for="(item, i) in mappedGallery"
            :key="`${i}-carousel-item`"
          >
            <v-img
              height="90vh"
              :src="item?.url"
              :lazy-src="item?.lazySrc"
              contain
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular indeterminate color="grey lighten-2" />
                </v-row>
              </template>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <v-btn
          color="white"
          icon
          style="position: absolute; top: 15px; right: 15px"
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div
          color="transparent"
          flat
          dense
          class="d-flex justify-center"
          style="position: absolute; bottom: 25px; left: 0; width: 100%"
        >
          <slot
            name="actions"
            :index="imageView.index"
            :image="selectedImage"
            :close="close"
          ></slot>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { RecycleScroller } from "vue-virtual-scroller";

export default {
  components: { RecycleScroller },
  data: () => ({
    imageView: {
      open: false,
      index: 0,
    },
    keyDownInterval: null,
    zoomIsVisible: false,
    zoomLevel: 1,
    zoomLevels: [
      { cols: 3, sm: 3, md: 3, lg: 2, xl: 1 },
      { cols: 4, sm: 4, md: 4, lg: 3, xl: 2 },
      { cols: 6, sm: 6, md: 6, lg: 4, xl: 3 },
    ],
    componentWidth: 0,
  }),
  methods: {
    zoomIn() {
      if (this.zoomLevel < this.zoomLevels.length - 1) this.zoomLevel++;
    },
    zoomOut() {
      if (this.zoomLevel > 0) this.zoomLevel--;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    onkeydown(e) {
      if (!this.imageView.open) return;
      if (this.keyDownInterval) return;
      this.keyDownInterval = setInterval(() => {
        this.keyDownInterval = null;
      }, 200);

      if (e.keyCode === 37) {
        this.imageView.index =
          (this.imageView.index - 1 + this.gallery.length) %
          this.gallery.length;
      } else if (e.keyCode === 39) {
        this.imageView.index = (this.imageView.index + 1) % this.gallery.length;
      }
    },
    close() {
      this.imageView.open = false;
    },
    imgClick(index) {
      this.imageView.index = index;
      this.imageView.open = true;
    },
    formatUrl(url, quality) {
      return url.replace(/raw$/, quality);
    },
    onIntersect(entries, observer) {
      this.zoomIsVisible = entries[0].isIntersecting;
    },
    centralizeImage(index) {
      const el = document.getElementById(`gallery-${index}`);
      if (!el) return;
      const rect = el.getBoundingClientRect();
      const top = rect.top + window.scrollY;
      const height = rect.height;
      const windowHeight = window.innerHeight;
      const scrollY = top - (windowHeight - height) / 2;
      this.$vuetify.goTo(scrollY, { duration: 500, easing: "easeInOutCubic" });
    },
    updateWidth() {
      this.componentWidth = this.$refs.galleryItens.offsetWidth;
    },
  },
  watch: {
    "imageView.index"(val) {
      this.$emit("change", this.gallery[val]);
      this.centralizeImage(val);
    },
    "imageView.open"(val) {
      const scrollY = window.scrollY;
      if (val) {
        if (!this.$router.query?.o)
          this.$router.replace({ query: { o: true } }).catch(() => {});
      } else if (!val && this.$route.query.o)
        this.$router.replace({ query: {} }).catch(() => {});

      this.$nextTick(() => {
        window.scrollTo(0, scrollY);
      });
    },
    "$route.query.o"(val) {
      if (val === "true" || !this.imageView.open) return;
      else this.imageView.open = false;
    },
  },
  computed: {
    itensPerRow() {
      const currentSize = this.$vuetify.breakpoint.name;
      return (
        12 /
        (this.fixCols ||
          this.zoomLevels[this.zoomLevel][currentSize] ||
          this.zoomLevels[this.zoomLevel].cols)
      );
    },
    selectedImage() {
      return this.gallery[this.imageView.index];
    },
    mappedGallery() {
      return this.gallery.map((item) => ({
        ...item,
        url: item?.url || item,
        preview: this.formatUrl(item?.url || item, this.previewQuality),
        lazySrc: this.formatUrl(item?.url || item, "lazy"),
      }));
    },
  },

  mounted() {
    document.addEventListener("keydown", this.onkeydown);
    window.addEventListener("resize", this.updateWidth);
    this.updateWidth();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onkeydown);
    window.removeEventListener("resize", this.updateWidth);
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    gallery: {
      type: Array,
      required: true,
    },
    previewQuality: {
      type: String,
      default: "medium",
    },
    fixCols: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.dialog-no-shadow {
  box-shadow: none;
}
</style>
