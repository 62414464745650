<template>
  <div class="pa-3">
    <v-switch
      :input-value="options.allowJoin"
      @change="setValue('allowJoin', $event)"
      label="Permitir que os convidados entrem no grupo"
      class="mt-0"
    />
  </div>
</template>

<script>
const defaultOptions = () => ({
  allowJoin: true,
});

export default {
  props: {
    value: {
      type: {},
      default: defaultOptions(),
    },
  },
  methods: {
    setValue(key, value) {
      this.options = Object.assign({}, this.options, { [key]: !!value });
    },
  },
  computed: {
    options: {
      get() {
        return Object.assign(defaultOptions(), this.value || {});
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {
    this.$emit("input", this.options);
  },
};
</script>

<style lang="scss" scoped></style>
