<template>
  <div class="d-flex flex-wrap gap-4">
    <v-card outlined :loading="loading" class="rounded-lg flex-grow-1">
      <div class="d-flex gap-3 pa-3">
        <v-icon size="36">mdi-eye</v-icon>
        <div>
          <span class="text-overline" style="line-height: 1em">
            Visualizações
          </span>
          <h5 class="mb-0">{{ screenPageViews }}</h5>
        </div>
      </div>
    </v-card>
    <v-card outlined :loading="loading" class="rounded-lg flex-grow-1">
      <div class="d-flex gap-3 pa-3">
        <v-icon size="36">mdi-account-group</v-icon>
        <div>
          <span class="text-overline" style="line-height: 1em">Usuários</span>
          <h5 class="mb-0">{{ totalUsers }}</h5>
        </div>
      </div>
    </v-card>
    <v-card outlined :loading="loading" class="rounded-lg flex-grow-1">
      <div class="d-flex gap-3 pa-3">
        <v-icon size="36">mdi-clock-time-four-outline</v-icon>
        <div>
          <span class="text-overline" style="line-height: 1em">
            Duração média da sessão
          </span>
          <h5 class="mb-0">{{ averageSessionDuration | duration }}</h5>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  data: () => ({}),
  computed: {
    totalUsers() {
      return parseInt(this.data.resume.totals.totalUsers);
    },
    averageSessionDuration() {
      return parseInt(this.data.resume.totals.averageSessionDuration);
    },
    screenPageViews() {
      return parseInt(this.data.resume.totals.screenPageViews);
    },
  },
  filters: {
    duration(value) {
      const minutes = Math.floor(value / 60);
      const seconds = value - minutes * 60;

      return `${minutes}m ${seconds.toFixed(0)}s`;
    },
  },
  props: {
    data: {
      type: Object || null,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
