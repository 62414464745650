<template>
  <div class="pt-4 pb-2">
    <div class="d-flex justify-space-between">
      <div>
        <span class="d-block">
          <b>Realizada por:</b> {{ object.User.name }}
        </span>
        <span class="d-block">
          <b>Pago por:</b> {{ object.payedFrom || "Não informado" }}
        </span>
        <span class="d-block">
          <b>Pago para:</b> {{ object.payedTo || "Não informado" }}
        </span>
        <span class="d-block">
          <b>Forma de Pagamento:</b>
          {{ paymentTypes[object.paymentType] || "Desconhecido" }}
        </span>
      </div>
    </div>
    <span class="d-block">
      <b>Descrição:</b><br />
      <pre style="font: inherit">{{ object.description }}</pre>
    </span>
    <div class="d-flex flex-column text-end mt-2">
      <span>Criado em: {{ object.createdAt | date }}</span>
      <span>Última atualização em: {{ object.updateAt | date }}</span>
    </div>
    <v-btn v-if="hasPermission(4096)" outlined small block @click="edit">
      <v-icon left>mdi-pencil</v-icon> Editar
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    paymentTypes: {
      UNKNOWN: "Desconhecido",
      CREDIT_CARD: "Cartão de Crédito",
      PIX: "Pix",
      PAYPAL: "Paypal",
      BANK_TRANSFER: "Transferência Bancária",
      COURTESY: "Cortesia",
      MONEY: "Dinheiro",
    },
  }),
  methods: {
    edit() {
      this.$emit("edit", this.object);
      this.$root.$emit("transaction-modal", this.object);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
