<template>
  <div class="pt-4 pb-2">
    <v-data-table hide-default-footer :headers="headers" :items="object">
      <template v-slot:item.amount="{ item }">
        {{ item.amount | currency }}
      </template>
      <template v-slot:item.paymentType="{ item }">
        {{ paymentTypes[item.paymentType] || "Desconhecido" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    headers: [
      {
        text: "Recolhido de",
        value: "from.name",
      },
      {
        text: "Recolhido por",
        value: "by.name",
      },
      {
        text: "Valor",
        value: "amount",
      },
      {
        text: "Forma de Pagamento",
        value: "paymentType",
      },
    ],
    paymentTypes: {
      UNKNOWN: "Desconhecido",
      CREDIT_CARD: "Cartão de Crédito",
      PIX: "Pix",
      PAYPAL: "Paypal",
      BANK_TRANSFER: "Transferência Bancária",
      COURTESY: "Cortesia",
      MONEY: "Dinheiro",
    },
  }),
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
