<template>
  <div class="my-4 mx-1">
    <v-card outlined class="pa-4 mb-3">
      <b class="mb-0">Vendas</b>
      <v-data-table
        dense
        :headers="headersPayments"
        :items="data.payments"
        sort-by="createdAt"
        class="dense"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ item.createdAt | date }}
        </template>
        <template v-slot:item.owner="{ item }">
          {{ item.owner.phone }} -
          {{ item.owner.name || "Não cadastrado" }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="item.status | status('color')" small label outlined>
            {{ item.status | status("text") }}
          </v-chip>
        </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount | currency }}
        </template>
        <template v-slot:item.ticket="{ item }">
          {{ item.ticketGroup }} - {{ item.ticketBlock }}
        </template>
      </v-data-table>
    </v-card>
    <v-card outlined class="pa-4">
      <b class="mb-0">Recolhimentos</b>
      <v-data-table
        dense
        :headers="headersRecall"
        :items="data.recalls"
        sort-by="date"
        class="dense"
      >
        <template v-slot:item.date="{ item }">{{ item.date | date }} </template>
        <template v-slot:item.amount="{ item }">
          {{ item.amount | currency }}
        </template>
        <template v-slot:item.by="{ item }"> {{ item.by.name }} </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import OfflineRecallSendMessage from "../modals/OfflineRecallSendMessage.vue";

export default {
  components: { OfflineRecallSendMessage },
  name: "OfflinePaymentsManagement",
  computed: {
    headersPayments() {
      return [
        {
          text: "Data",
          value: "createdAt",
          sortable: this.$vuetify.breakpoint.smAndUp,
          align: "start",
        },
        {
          text: "Valor",
          value: "amount",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
        {
          text: "Comprador",
          value: "owner",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
        {
          text: "Status",
          value: "status",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
        {
          text: "Lote",
          value: "ticket",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
      ];
    },
    headersRecall() {
      return [
        {
          text: "Data",
          value: "date",
          sortable: this.$vuetify.breakpoint.smAndUp,
          align: "start",
        },
        {
          text: "Valor",
          value: "amount",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
        {
          text: "Recebido por",
          value: "by",
          sortable: this.$vuetify.breakpoint.smAndUp,
        },
      ];
    },
  },
  filters: {
    date(value) {
      if (!value) return "";
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
    currency(value) {
      value = parseFloat(value || 0);
      return value.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>