<template>
  <div class="d-flex flex-wrap gap-4">
    <v-card outlined :loading="loading" class="rounded-lg flex-grow-1">
      <div class="pa-2">
        <h6 class="mb-0 text-center">Visualizações por página</h6>
        <div class="d-flex w-100 gap-2 justify-space-around align-center">
          <div class="d-flex align-center">
            <v-icon size="28" left>mdi-shopping</v-icon>
            <div>
              <span class="text-overline" style="line-height: 1em"> Loja </span>
              <h6 class="mb-0">{{ reducePages.shop }}</h6>
            </div>
          </div>
          <div class="d-flex align-center">
            <v-icon size="28" left>mdi-image-multiple</v-icon>
            <div>
              <span class="text-overline" style="line-height: 1em">
                Galeria
              </span>
              <h6 class="mb-0">{{ reducePages.gallery }}</h6>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: { ApexCharts },
  data: () => ({}),
  computed: {
    reducePages() {
      return this.data.resume.rows.reduce(
        (acc, row) => {
          const page = row.dimensions[0];
          const views = parseInt(row.metrics[1]);
          if (page.endsWith("/gallery")) acc.gallery += views;
          else acc.shop += views;
          return acc;
        },
        { shop: 0, gallery: 0 }
      );
    },
  },
  props: {
    data: {
      type: Object || null,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
};
</script>

<style></style>
