<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          label="Idade mínima"
          :items="ageOptions"
          required
          outlined
          dense
          hide-details
          v-model="policies.ageGroup"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6">
        <v-select
          label="Idade mínima acompanhado dos pais"
          :items="ageOptionsWithParent"
          :disabled="policies.ageGroup === 0"
          required
          outlined
          dense
          hide-details
          v-model="policies.ageWithParent"
        ></v-select>
      </v-col>
    </v-row>
    <v-alert type="info" dense text border="left">
      <age-group-text :party="policies" />
    </v-alert>
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

import AgeGroupText from "../../../global/party/AgeGroupText.vue";
export default {
  components: { AgeGroupText },
  data() {
    return {
      ageOptions: [
        { text: "Livre", value: 0 },
        { text: "10 anos", value: 10 },
        { text: "12 anos", value: 12 },
        { text: "14 anos", value: 14 },
        { text: "16 anos", value: 16 },
        { text: "18 anos", value: 18 },
      ],
      policies: {
        ageGroup: this.party?.ageGroup || 18,
        ageWithParent: this.party?.ageWithParent || 0,
      },
    };
  },
  methods: {
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        ageGroup: this.policies.ageGroup,
        ageWithParent: this.policies.ageWithParent,
      });
    },
  },
  computed: {
    ageOptionsWithParent() {
      return this.ageOptions
        .filter((option) => option.value < this.policies.ageGroup)
        .map((option) => {
          return {
            text: option.value == 0 ? "Não permitido" : option.text,
            value: option.value,
          };
        });
    },
  },
  watch: {
    "policies.ageGroup": {
      handler: function (val, oldVal) {
        if (val <= this.policies.ageWithParent) {
          this.policies.ageWithParent = 0;
        }
      },
      deep: true,
    },
    party: {
      handler: function (val, oldVal) {
        this.policies.ageGroup = val.ageGroup || 18;
        this.policies.ageWithParent = val.ageWithParent || 0;
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
</style>