import http from "@/http-common";

const create = async (data) => {
  return await http
    .post("/admin/organization", data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAll = async () => {
  return await http
    .get("/admin/organization")
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const report = async (orgId, params) => {
  return await http
    .get(`/admin/financial/${orgId}/report`, { params })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

const getAllTransaction = async (orgId, params) => {
  return await http
    .get(`/admin/financial/${orgId}/transaction`, { params })
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const createTransaction = async (orgId, data) => {
  return await http
    .post(`/admin/financial/${orgId}/transaction`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const updateTransaction = async (orgId, data) => {
  const id = data.id;
  delete data.id;

  return await http
    .put(`/admin/financial/${orgId}/transaction/${id}`, data)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  report,
  transaction: {
    getAll: getAllTransaction,
    create: createTransaction,
    update: updateTransaction,
  },
};
