<template>
  <v-card
    outlined
    class="mx-1 pa-2 mt-1"
    elevation="0"
    v-intersect="onIntersect"
  >
    <div class="d-flex justify-space-between align-center mb-0">
      <p class="font-weight-medium text-14 lh-1 ml-2 mb-0">Resumo de Vendas</p>
      <v-btn
        :to="{
          name: 'admin.party.management',
          params: { partyId: party.id },
          hash: '#reports',
        }"
        text
        color="primary"
        x-small
      >
        Ver relatório
      </v-btn>
    </div>
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <div
      v-if="!party.isActive && !loaded"
      class="d-flex flex-column align-center"
    >
      <span class="mb-0 text-center text-14 mb-1">
        Evento encerrado. Clique para carregar o resumo.
      </span>
      <v-btn @click="loadReport" text block small> Carregar resumo </v-btn>
    </div>

    <v-card-text v-else class="d-flex flex-wrap justify-space-around px-6 pa-2">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="d-flex flex-column align-center px-4"
          >
            <v-skeleton-loader
              v-if="!resume"
              type="text"
              width="50"
              height="20"
            />
            <h6 v-else class="mb-0">{{ resume.totalTickets }}</h6>
            <span class="text-caption">Ingressos Vendidos</span>
          </div>
        </template>
        <p class="mb-0">Total Vendido</p>
        <p class="mb-0">Sem cortesias</p>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="d-flex flex-column align-center px-4"
          >
            <v-skeleton-loader
              v-if="!resume"
              type="text"
              width="50"
              height="20"
            />
            <h6 v-else class="mb-0">
              {{ (resume.total / resume.totalTickets) | currency }}
            </h6>
            <span class="text-caption">Ticket Médio</span>
          </div>
        </template>
        <p class="mb-0">Valor líquido / Ingressos vendidos</p>
        <p class="mb-0">Sem cortesias</p>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="d-flex flex-column align-center px-4"
          >
            <v-skeleton-loader
              v-if="!resume"
              type="text"
              width="50"
              height="20"
            />
            <h6 v-else class="mb-0">
              {{ resume.total | currency }}
            </h6>
            <span class="text-caption">Vendas Liquida</span>
          </div>
        </template>
        <p class="mb-0">Total Vendido</p>
        <p class="mb-0">Taxas Descontadas</p>
      </v-tooltip>
    </v-card-text>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
const REPORT = ORGANIZATION.party.reports;

export default {
  data: () => ({
    loaded: false,
    loading: false,
    error: null,
    resume: null,
  }),
  methods: {
    onIntersect(entries, observer) {
      if (this.loaded || this.loading) return;
      if (!this.party.isActive) return;
      if (entries[0].isIntersecting) this.loadReport();
    },
    async loadReport() {
      try {
        this.loaded = true;
        this.loading = true;
        this.error = null;
        const { resume } = await REPORT.resume(
          this.party.organizationId,
          this.party.id
        );
        this.resume = resume;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },

  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
